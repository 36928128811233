import React from "react";
import { Helmet } from "react-helmet";
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import mechImg from "../../../assets/Allcoureses/machine learning.png";
import Section3 from './../../../components/innerPage/section3/section3';

function MachineLearning() {
 
  // section1Data
  const section1Data = {
    sectImg: mechImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Machine Learning</span> with the best instructors
      </h1>
    ),
    description:
     "Our exhaustive machine learning course covers fundamental ideas, algorithms, and practical applications. Master data preprocessing, model training, and deployment. Python, TensorFlow, and sci-kit-learn expertise. Today, unlock your AI potential!",
    link: "/internship-program",
  };
 
  // section2Data
  const section2Data = {
    description: "Machine learning details all the accurate outcomes and the learning-through-experience that the machine collects through real-time analysis. It is a subset of AI focused on the use of data and algorithms.",
    points: [
      "Learn all the fundamentals of how machine learning functions and its various applications.",
      "Learn various detailed and professional courses that help you understand the necessities to make the program function.",
      "Core concepts like NLP, boosting algorithm, data science, deep learning which helps build your machine learning outcomes",
    ]
  };

  // section 3
  const section3Data = [
    {
      topic: "Statistics fundamentals:",
      subTopics: [
        "Probability",
        "Covariance and correlation",
        "Graphically displaying single variable",
        "Measures of spread and location",
        "Bayes’ Theorem",
      ],
    },
    {
      topic: "ML with Python:",
      subTopics: [
        "Supervised and unsupervised learning.",
        "Python libraries for machine learning.",
        "Applications of machine learning.",
        "Merits of using python with ML.",
      ],
    },
    {
      topic: "Regression:",
      subTopics: [
        "Training, testing, forecast and predicting.",
        "Program the best fit slope and best fit line.",
        "R squared and coefficient of determination theory.",
        "Model evaluation methods.",
      ],
    },
    {
      topic: "Clustering:",
      subTopics: [
        "Handling non-numerical data for ML.",
        "K-means with titanic dataset.",
        "K-means from scratch in python.",
        "Hierarchical clustering with mean shift introduction.",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Machine Learning Internships | Machine Learning courses</title>
        <meta name="description" content="Academor machine learning internship training courses will help in-depth course is designed to explain the complexities of machine learning" />
        <meta name="keywords" content="Online machine learning internships, Online machine learning Internship certification, Python Internship for Machine Learning, Machine Learning Courses, Machine Learning Internships near me, Best Machine Learning courses, Machine Learning Courses near me" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default MachineLearning;

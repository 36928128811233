import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import icEngImg from "../../../assets/Allcoureses/IC engine.png"
import Section3 from './../../../components/innerPage/section3/section3';

function IcEngine() {
      //section1Data
  const section1Data = {
    sectImg:icEngImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>IC Engine</span> with the best instructors
      </h1>
    ),
    description:
     "Our IC Engine course covers thermodynamics, combustion processes, fuel injection, emissions control, and performance analysis in detail. Acquire practical skills through hands-on exercises and real-world examples. Ideal for engineering enthusiasts and automotive professionals pursuing advanced knowledge of internal combustion engines. Join us on a thrilling educational adventure!",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "fundamentals and cyclic process of IC engines and the power production of each type.",
    points : [
      "Integrated engines that involve great researched theories and practical cycles.",
      "IC and SI engines and their classifications",
      "Thermodynamics analysis and detailed cyclic procedures and its efficiency."
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Introduction",
      subTopics: [
       "CI and SI engines",
       "Basic governing theories",
      ],
    },
    {
      topic: "Thermodynamic analysis",
      subTopics: [
       "Thermodynamic concepts",
       "Principles guiding various cycles",
       "Functioning of engines",
      ],
    },
    {
      topic: "Combustion process",
      subTopics: [
        "Theory behind the combustion chambers"
      ],
    },
    {
      topic: "Combustion chambers",
      subTopics: [
        "Information on combustion chambers",
        "Types of combustion chambers",
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default IcEngine

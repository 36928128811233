import React from 'react'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import "./carousel.css"

function Carousel({customClass='',...props}) {
  return (
       <OwlCarousel className={`owl-theme ${customClass}`} {...props.options}>
        {props.children}
       </OwlCarousel>
  )
}

export default Carousel
import React from 'react'
import "./courseCard.css"
import cardImg from "../../assets/courseCardImages/machineLearning.png"
import greyStar from "../../assets/icons/greyStar.svg"
import redStar from "../../assets/icons/redStar.svg"
import book from "../../assets/icons/book.svg"
import user from "../../assets/icons/user.svg"
import timer from "../../assets/icons/timer.svg"
import { Link } from 'react-router-dom';


function CourseCard({courseName='',departmentName='',rating=5,customClass='',courseLink,courseImg=cardImg}) {
    const arr = [1,2,3,4,5]
  return (<div className={'pt-16 relative overflow-hidden course-card-wrapper '+ customClass}>
    <span className='course-curve left-curve'></span>
    <span className='course-curve right-curve'></span>
    <div className='relative course-card' >
       <div className='-translate-y-10'>
       <img alt='courseImg' src = {courseImg} className='course-img m-auto block' />
        <div className='flex justify-between pt-5 pb-2 items-center m-auto w-[80%]'>
            <section className='flex w-[80%]  m-auto gap-[10px] items-center '>
            <img src={book} className='w-[19px] h-[19px]' alt = 'book' />
            <h2 className='font-bold text-sm text-[#CDCDCD] hide-text'>{departmentName}</h2>
            </section>     
            <div className='flex gap-[1px] items-center'>
                {arr?.length>0 && arr.map((item)=>{
                    return <img className='w-[15px] h-[15px]' alt = 'star' key={item} src = {item<=rating?redStar:greyStar}/>
                })}               
                </div> 
        </div>
          <section className = 'w-[70%] m-auto'>
            <h2 className='font-[900] pb-4 text-xl hide-text'>{courseName}</h2>
            <div className= 'flex flex-col gap-[10px]'>
                <div className='flex gap-[10px]'>
                    <img alt = 'user' src = {user}/>
                    <p className='text-[12px] hide-text'>Customized Timespan</p>
                </div>
                <div className='flex gap-[10px]'>
                    <img alt = 'timer' src = {timer} />
                    <p className='text-[12px] hide-text'>1-1 Mentorship & Job Support</p>
                </div>
            </div>
          </section>
         
       </div>
       <Link className='p-4 block  text-center w-[100%] bg-[#FFEBEB] text-[#FD0000] viewCourse' to = {courseLink}>View Details</Link>
    </div>
  </div>)
}

export default CourseCard



import React from 'react';
import logo from "../../assets/home/logo.png"

function Thanks({ onClick }) {
  return (
    <>
      <div className='popUpMain'>
        <div className='popUpform' style={styles.popUpform}>
          <p className='popupcross' onClick={onClick}>&#10060;</p>
          <img src={logo} alt="logo" style={styles.logo} />
          <div className='popupFromDetails' style={styles.popupFromDetails}>
            <p><b>Thank You for contacting us. Our team will reach out to you within 24 hours!</b></p>
          </div>
        </div>
      </div>
    </>
  )
}

export default Thanks;

const styles = {
  popUpform: {
    /* Default styles for the popUpform */
  },
  logo: {
    /* Styles for the logo image */
  },
  popupFromDetails: {
    /* Styles for the popupFromDetails div */
  },
};

// Media Queries
const smallScreen = '@media (max-width: 767px)';
const mediumScreen = '@media (min-width: 768px) and (max-width: 1023px)';
const largeScreen = '@media (min-width: 1024px)';

// Update the styles object with media query-specific styles
styles.popUpform = {
  /* Add default styles here */

  [smallScreen]: {
    /* Add styles specific to smaller screens here */
  },

  [mediumScreen]: {
    /* Add styles specific to medium-sized screens here */
  },

  [largeScreen]: {
    /* Add styles specific to larger screens here */
  }
};

import React from 'react';
import SectionUnderlineHeading from '../../sectionUnderLineHeading/sectionUnderlineHeading';
import flyingBlocks from "../../../animations/flyingBlocks.json";

import defaultImage from '../../../assets/innerPage/innersec2img.png'
import Lottie from "react-lottie"

function Section2({description="",points, img=defaultImage}) {
  const animationOptions = {
    loop: true,
    animationData: flyingBlocks,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className = 'p-[5%_10%] md:p-[5%_5%_3%]'>
    <SectionUnderlineHeading heading={"Course Overview"}/>
    <div className='flex flex-wrap-reverse  md:flex-row'>
        <div className='w-[100%] md:w-[50%] self-center relative overflow-hidden'>
          <div className='absolute w-[100%] z-0 top-0 left-0'>
            <Lottie options={animationOptions}/>
          </div>
         <img className='w-[80%] md:ml-[3%] relative z-10 m-auto md:w-[73%]' src={img} alt="image"/>
        </div>
        <div className='w-[100%] flex flex-col p-[10%_0]  gap-5 md:w-[50%] md:gap-6 md:p-[5%_5%_0_0]'>
            <p className= 'paragraph'>{description}</p>
             {points?.length>0 && points.map((item,i)=>{
              return <p className= 'paragraph flex gap-4 items-baseline' key = {i}><span className = 'min-w-[10px] h-[10px] bg-[#FD0000] block'></span> {item}</p>
             })}
        </div>
    </div>
    
    </div>
  )
}

export default Section2
import React from 'react'
import plus from "../../assets/icons/plus.svg"
import minus from "../../assets/icons/minus.svg"
import "./accordian.css"

function Accordian({head='',body='',customClass=''}) {
    const mainRef = React.useRef()
    const bodyRef = React.useRef()
    const handleAccordianClick = ()=>{
        mainRef.current.classList.toggle('active');
        bodyRef.current.classList.toggle('active');
        bodyRef.current.style.maxHeight =  bodyRef.current.style.maxHeight===''?bodyRef.current.scrollHeight+'px':''
    }

  return (
    <div ref ={mainRef} className={'accordian-main '+customClass}>
        <div  onClick ={handleAccordianClick} className='accordian-head'>
            <img className='plus' alt = 'plus' src= {plus}/>
            <img className='minus' alt = 'minus' src = {minus}/>
         {head}
        </div>
        <div ref = {bodyRef} className = 'accordian-body'>
           {body}
        </div>
    </div>
  )
}

export default Accordian
import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import digiImg from "../../../assets/Allcoureses/digital marketing.png"
import Section3 from './../../../components/innerPage/section3/section3';

function BusinessAnalytics() {
     //section1Data
  const section1Data = {
    sectImg:digiImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Digital Marketing</span> with the best instructors
      </h1>
    ),
    description:
     "The Business Analytics course equips students with the skills necessary to analyze data, derive meaningful insights, and make decisions based on data. The course covers statistical techniques, data visualization, predictive modeling, and a variety of instruments such as Excel. Students master data mining, cleansing, and interpretation techniques that support strategic decision making and propel business success through actionable intelligence.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "A greatly growing discipline that intersects the field of market and digital application in the growth support of organizations. Learn to advertise through digital channels using great methods you learn with us.",
    points : [
      "Learn concepts and work with Search Engine Operations and promotions on websites and web browsers.",
      "Advertising and promotions on social media marketing of customer brands and organizations.",
      "Become a professional in web analytics with the best guidance from our experienced professionals."
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Basics of Business Analytics",
      subTopics: [
        "Microsoft excel",
        "Basics of SQL",
        "Database of SQL",
        "Decision Tree"
      ],
    },
    {
      topic: "Concepts",
      subTopics: [
        "Data pre-processing &amp; Data Mining",
        "Linear Regression",
        "Python basics",
        
      ],
    },
    {
      topic: "Function and Analysis",
      subTopics: [
        "Exploratory Data Analysis",
        "Date functions",
        "Sets Operations",
        "Group-by and Having",
        "Windows function"
      ],
    },
    {
      topic: "Process used",
      subTopics: [
        "CTE",
        "Subquery",
        "V look-up",
        "Pivot-tables",
        "Time function"
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default BusinessAnalytics

import React from "react";
import "./section3.css";
import SectionUnderlineHeading from "../../sectionUnderLineHeading/sectionUnderlineHeading";
import ballAnimation from "../../../animations/smallBalls.json";
import Lottie from "react-lottie";
import girl from "../../../assets/innerPage/section3Girl.png";
import Accordian from "../../accordian/accordian";

function Section3({data}) {
   
  const animationOptions = {
    loop: true,
    animationData: ballAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
 
  return (
    <div className="relative p-[_5%] bg-[#FFF5F5]">
      <SectionUnderlineHeading heading="Course Curriculum" />

      <div className="flex flex-wrap items-center  z-10 relative left-[0%] w-[100%]">
       
        <div className="flex flex-col p-4 rounded-[10px] gap-[20px] w-[100%] max-h-[300px]  tablet:w-[50%] tablet:max-h-[430px] tablet:bg-[#fff]  overflow-scroll">
            {data?.length>0 && data.map((item,i)=>{
              return <Accordian 
                customClass="topicAccordian"
                key = {i}
                head = {<div className="flex basis-[100%] items-center justify-between">
                    <p className = 'font-[700] text-[13px] sm:text-[1rem]'>{item?.topic}</p>
                    <p className='font-[600] text-[9px] sm:text-[14px]  bg-[#FFCCC9] rounded-[50%_0_0_50%] p-[5px_3%_5px]'>{item?.subTopics?.length} Topics</p>   
                </div>}
                body = {<div className='flex flex-wrap gap-[20px_0px] pt-[15px]'>
                    {item?.subTopics?.length>0 && item?.subTopics.map((subTopic,i2)=>{
                        return <p className="w-[100%] text-[12px] sm:text-[1rem] tablet:w-[90%] text-[#6F6F6F]" key = {i2}><span className='font-[700] text-[#000] pr-2'>{i2+1<10?`0${i2+1}`:`${i2+1}`}</span> {subTopic}</p>
                    })}
                </div>}
              />
            })}  
        </div>
       
        <div className="hidden w-[50%] tablet:block">
          <img className='w-[70%] ml-[18%]' src={girl} alt="girl" />
        </div>
      </div>
      <div className="absolute z-[0] top-0 left-[0%] w-[100%] h-[100%]">
        <Lottie options={animationOptions} />
      </div>
    </div>
  );
}

export default Section3;

import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import marketingImg from "../../../assets/Allcoureses/marketing.png"
import Section3 from './../../../components/innerPage/section3/section3';

function Marketing() {
     //section1Data
  const section1Data = {
    sectImg:marketingImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Marketing</span> with the best instructors
      </h1>
    ),
    description:
     "Our marketing course is intended to provide students with a thorough understanding of marketing principles and techniques. The course provides practical insights and real-world examples for key topics including market analysis, consumer behavior, branding, digital marketing, and advertising. Students will acquire the skills necessary to construct effective marketing campaigns, target specific audiences, and propel business growth. Through interactive sessions and hands-on projects, participants will acquire the skills necessary to succeed in the dynamic and competitive marketing environment of the present day.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: `Learn how to move around the various elements of the digital marketing landscape so you may expand your reach. Recognize the different methods and tools utilized to build a brand's online presence.`,
    points : [
      "SEO Marketing",
      "Social Media Marketing",
      "Email Marketing",
      "Web Analytics",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Introduction to Marketing Management",
      subTopics: [
      `Introduction to basic Marketing Management concepts and right usage of the terminologies.`
      ],
    },
    {
      topic: "Marketing and Company Orientation",
      subTopics: [
     `Viewing Consumers from a marketing perspective to understand their needs and requirements.`
      ],
    },
    {
      topic: "Understanding Consumers",
      subTopics: [
       `Understanding marketing of a company’s products and services. Greater understanding of consumers by identifying, analyzing Consumer Behavior, Capabilities and Value.`
      ],
    },
    {
        topic: "Market Research",
        subTopics: [
         `Understanding the process of Market Research and techniques to implement market research.`
        ],
      },
      {
        topic: "STP",
        subTopics: [
         `Understanding Segmentation, Targeting & Positioning and Marketing Fundamentals. An Introduction to Market Mix.`
        ],
      },
      {
        topic: "Retail Markets",
        subTopics: [
         `Understanding the process of lead generation, marketing funnel, importance of Retail Marketing, Commercial Awareness, and Organised Sale.`
        ],
      },
      {
        topic: "Digital Marketing",
        subTopics: [
         `Introduction to Digital Marketing; Understanding and application of Product Automation, Subliminal Advertising, and Product Design Survey.`
        ],
      },
      {
        topic: "SMM, Analysis, SEO",
        subTopics: [
         `Compilation of Case studies to understand the concepts better and deduce relevant solutions and results.`
        ],
      }
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default Marketing

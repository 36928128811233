import React from "react";
import '../privacy/privacy.css'

function Termsandconditions() {
  return (
    <>
    <div className="grey">
        <div className="content">
        <section>
        <h1 className="hero">Terms & Conditions</h1>
        <p>
          This archive is distributed as per the arrangements of Rule 3 (1) of
          the Information Technology (Intermediaries Guidelines) Rules, 2011
          that require distributing the standards and guidelines, Privacy
          Policy, and Terms of Use for access or use of the www.academor.com
        </p>
        <p>
          ("Academor," "us," "our" or "we") gives this site to you ("User")
          under the accompanying agreements (this "Understanding" or "Terms of
          Use"). Our Privacy Policy (as presented on our site), which clarifies
          how we gather and use data from guests to our site is fused by
          reference in these Terms of Use. By utilizing our site (the "Site") or
          presenting any data to us, you agree to and consent to be limited by
          these Terms of Use and our Privacy Policy. By involving the Site in
          any way, including however not restricted to visiting or perusing the
          Site, you consent to be limited by this Agreement, the Privacy Policy,
          and any remaining working principles, arrangements, and methods that
          might be distributed by us now and then on the Site, every one of
          which is joined by reference and every one of which might be refreshed
          by us occasionally without notice to you. Your utilization of the Site
          is represented by the variant of this Agreement in actuality on the
          date of utilization.
        </p>
      </section>
      <section>
        <h2>Account Registration-</h2>
        <p>
          Assuming you are younger than 18, kindly don't pursue a record.
          Assuming you are getting to or utilizing our site in the interest of
          any substance, you address that you are approved to acknowledge these
          Terms of Use for that element's sake, that element consents to be
          capable of Academor assuming you abuse these Terms of Use.
        </p>
        <p>
          You might get to certain spaces of our site(s) without enlisting. In
          any case, to get to specific elements of the Site or to post substance
          on the Site you should enroll to make a record ("Account"). You should
          finish the enrollment interaction by giving us current, complete, and
          exact data. Assuming you decide to enlist you should give a username
          and secret phrase just as other data that is essential for our
          enrollment interaction. You won't share your secret word, let any
          other person access your Account, or do anything more that may
          endanger the security of your Account. We reserve the privilege to
          drop any record whenever assuming we accept you are not inconsistent
          with any or the entirety of the terms.
        </p>
      </section>
      <section>
        <h2>Courses Offered And Payment-</h2>
        <p>
          You concur that Academor won't be at risk for any misfortune brought
          about by any unapproved utilization of your Mastercard or some other
          technique for installment by an outsider regarding the utilization of
          the Site. You postpone your entitlement to debate any installment made
          into your record and you will bear all expenses.
        </p>
        <p>
          Any endeavor to dupe Academor using Mastercards or different
          techniques for installment or any disappointment by you to respect
          charges or demands for installment will bring about a prompt end of
          your record.
        </p>
      </section>
      <section>
        <h2>Processing Of Account Payment-</h2>
        <p>
          Academor might utilize outsider electronic installment processors or
          potentially monetary foundations ("EPPs") to handle monetary
          exchanges. You permanently approve us, as important, to teach such
          EPPs to deal with such exchange and you irreversibly concur that
          Academor might give such directions for your sake as per your
          solicitations as submitted on the Site.
        </p>
      </section>
      <section>
        <h2>Change Of Pricing And Billing Terms-</h2>
        <p>
          We hold the right whenever to initiate new costs, and to change how
          much or the reason at deciding any costs or accuses of regard to any
          courses advertised. You consent to pay all charges, including
          pertinent expenses, as per the charging terms as a result at the time
          the cost or charge becomes payable.
        </p>
      </section>
      <section>
        <h2>Intellectual Property-</h2>
        <p>
          Academor explicitly holds generally protected innovation privileges in
          all text, programs, items, processes, innovation, content,
          programming, and different materials, which show up on this Website.
          The assemblage (which means the assortment, game plan, and
          get-together) of the substance on the Website is the elite property of
          Academor. Proliferation in entire or in a piece of the equivalent is
          precluded without the express composed consent of Academor.
        </p>
        <p>
          The brand names, logo, and mottos shown on the Site including the
          imprint Academor , (all in all the "Brand names") are possessed by
          Academor. Nothing on this Site ought to be interpreted as conceding
          any permit or right to utilize the Trademarks without the composed
          consent of Academor or such outsider proprietor of the Trademarks.
          Your abuse of the Trademarks, or some other substance on the Site,
          besides as given in these Terms of Use, is completely precluded. You
          are exhorted that Academor will forcefully uphold its licensed
          innovation freedoms to the furthest reaches of the law.
        </p>
        <p>
          Academor awards to the enlisted clients a non-selective,
          non-adaptable, revocable right to involve the substance on the Site
          for non-benefit, instructive, and research utilisation just, without
          the right of sublicense. Academor regards the licensed innovation of
          others, and we ask our Users and content accomplices to do likewise.
          The unapproved posting, proliferation, duplicating, dispersion,
          adjustment, public presentation, or public execution of protected
          works comprises encroachment of the copyright proprietor's privileges.
          As a state of your utilization of the Site, you make a deal to avoid
          utilizing any component of the Site to encroach the licensed
          innovation privileges of others in any capacity. We will end the
          records of any User, and square admittance to the Site of any User who
          rehash infringers of the copyrights, or other licensed innovation
          freedoms, of Academor or others. We maintain whatever authority is
          needed to make these moves whenever, in our only circumspection, with
          or without notice, and with next to no risk to the User who is stopped
          or to the User whose entrance is hindered.
        </p>
        <p>
          Licensed innovation Right" with the end goal of the Terms of Use, will
          mean all copyrights (counting without constraint the elite right to
          duplicate, appropriate duplicates of, show and play out the protected
          work and to plan subsidiary works), copyright enrollments, and
          applications, brand name freedoms (counting, without restriction,
          enlistments and applications), patent privileges (whether enrolled or
          unregistered), trademarks or business names, enrolled and unregistered
          plan privileges, veil work freedoms, proprietary advantages, moral
          privileges, creator's freedoms, freedoms in bundling, altruism, and
          other licensed innovation privileges, and all restorations and
          expansion thereof and generally privileges or types of insurance of a
          comparable sort or having a same or comparative impact to any of the
          abovementioned, which might remain alive anyplace on the planet,
          whether or not any of such freedoms emerge under the laws of India or
          some other state, nation or ward.
        </p>
      </section>
      <section>
        <h2>Data Protection/Privacy-</h2>
        <p>
          Kindly read our security strategy cautiously as you are consenting to
          be limited by that protection strategy. If it's not too much trouble,
          note that our protection strategy is refreshed consistently. You
          should check it each time you access the site.
        </p>
      </section>
      <section>
        <h2>Restricted Actions-</h2>
        <p>During the utilization of the Site, you will not:</p>
        <p>
          Endeavor to duplicate, alter, copy, impersonate, imitate, make
          subsidiary works from, casing, reflect, or download, all or any part
          of the Site in any structure or media or using any means.
        </p>
        <p>
          Endeavor to decompile, dismantle, figure out or if not endeavor to
          find any source code from, all or any piece of the site.
        </p>
        <p>
          Sell, exchange, rearrange or in any case industrially exploit any
          material from this Site except content explicitly and explicitly made
          accessible for reallocation.
        </p>
        <p>
          Access or utilize all or any piece of the Site to fabricate or make an
          item or administration which is like, or which contends with, the
          matter of Academor.
        </p>
        <p>
          Endeavor to get, or help outsiders in acquiring, unapproved admittance
          to the site.
        </p>
        <p>
          Misuse the utilization of site in any capacity including for
          publicizing or requesting to trade any items and additionally for
          making, sharing, and sending spontaneous business messages, mass
          email, "garbage mail", "spam" or junk letters.
        </p>
        <p>
          Make or send any infections, worms or deceptions, flood or mail bombs,
          or taking part willfully ignorant of administration assaults while
          utilizing the Site.
        </p>
        <p>
          Utilize the Site in any way that harms, cripples, overburdens, or
          disables the Site, or Academor's frameworks or servers, or the cloud
          or other stage on which we work, or potentially, in any case, impedes
          some other party's utilization and happiness regarding the Site.
        </p>
        <p>
          Access the site using any means other than through the connection
          point that is given by us to get to the Site.
        </p>
        <p>Grant any outsider to do any of the previous.</p>
        <p>
          You will utilize all sensible undertakings to forestall any unapproved
          admittance to, or utilization of, the Site and, in case of any such
          unapproved access or use, it will expeditiously inform us.
        </p>
        <h2>Third-Party Content-</h2>
        <p>
          The Site might give connect to or outline outsider sites or assets and
          may interface Users consequently to backers' or outsider's sites or
          assets. You recognize and concur that Academor isn't dependable or
          obligated for: (I) the accessibility or precision of such sites or
          assets; or (ii) the substance, items, or administrations on or
          accessible from such sites or assets. Connections to such sites or
          assets don't suggest any underwriting by Academor of such sites or
          assets or the substance, items, or administrations accessible from
          such sites or assets. You recognize sole liability regarding and
          accept all danger emerging from your utilization of any such sites or
          resources. You should audit the material outsider's terms
        </p>
      </section>
      <section>
        <h2>Notifications-</h2>
        <p>
          You consent to Academor sending you notices and significant messages
          from time to time by means of its sites, versatile applications, and
          email to furnish you a superior involvement in the administrations
          given by us. You concur that we might give notification to you on our
          site, or through an email shipped off an email address you gave, or
          through different means including however not restricted to your
          versatile number, phone, or email. You consent to stay up with the
          latest.
        </p>
        <h2>Grievance Officer-</h2>
        <p>
          As per Information Technology Act, 2000 and rules made thereunder, the
          name and contact subtleties of the Grievance Officer are given
          beneath:
        </p>
        <p>Name:</p>
        <p>Designation:</p>
        <p>Phone:</p>
        <p>Email:</p>
        <p>Time:</p>
      </section>
      <section>
        <h2>No Representations Or Warranties</h2>
        <p>
          Your admittance to the site is at your only danger. Our
          administrations are given on "With no guarantees" and "AS AVAILABLE"
          premise without guarantees of any sort, either express or suggested,
          including, yet not restricted to, inferred guarantees of
          merchantability, readiness for a specific reason, title, and
          non-encroachment. Academor makes no portrayal with respect to the
          fulfillment or precision of the data given on the Site. Academor
          doesn't embrace or address the unwavering quality or precision of any
          substance or data appropriated through or got to from the Site, and
          has not played out any examination concerning such data.
        </p>
        <p>
          To the degree given by law, Academor accepts no obligation or
          obligation regarding any blunders or oversights in the substance of
          the Site. Academor has not evaluated each of the connections given on
          the Site and isn't liable for the substance of any off-Site pages.
          Tapping on hyperlinks and visiting any off-Site pages is done at your
          own danger.
        </p>
      </section>
      <section>
        <h2>Change Or Termination-</h2>
        <p>
          We may, without earlier notification, change the Site, quit giving the
          Site, applications, or benefits, or make use of limits for the Site.
          We may for all time or briefly end or suspend your admittance to the
          Site without notice or obligation, under any condition or for reasons
          unknown, incorporating if in our only assurance you disregard any
          arrangement of these Terms of Use. Endless supply of these Terms of
          Use or your admittance to the Site under any circumstance or no
          justifiable excuse, you will keep on being limited by these Terms of
          Use which, by their temperament, ought to endure end, including
          without restriction proprietorship arrangements, guarantee
          disclaimers, reimbursement, and impediments of risk.
        </p>
      </section>
      <section>
        <h2>Limitation Of Liability-</h2>
        <p>
          TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER ACADEMOR
          NOR ITS AFFILIATES WILL BE LIABLE UNDER ANY THEORY OF LIABILITY
          (WHETHER IN CONTRACT, TORT, NEGLIGENCE, OR OTHERWISE) FOR ANY
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY
          DAMAGES, INCLUDING, DAMAGES FOR LOSS OF REVENUES, PROFITS, GOODWILL,
          USE, DATA, OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH PARTIES WERE
          ADVISED OF, KNEW OF, OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
          DAMAGES), ARISING FROM OR RELATING TO THE SITES OR SERVICES OR THESE
          TERMS OF USE.
        </p>
        <h2>Indemnification-</h2>
        <p>
          Client consents to repay, safeguard and hold innocuous Academor and
          its Affiliates from and against all possible cases, liabilities,
          harms, misfortunes, expenses, costs, and charges of any sort (counting
          sensible lawyers' expenses and lawful expenses), emerging from or
          connecting with: (a) any data (counting User Content or whatever other
          substance) that User or anybody utilizing User's record submits,
          posts, or communicates on or through the Sites or Services; (b) the
          utilization of the Sites or Services by User or anybody utilizing
          User's record; (c) the infringement of these Terms of Use by User or
          anybody utilizing User's record; or (d) the infringement of any
          freedoms of any outsider, including protected innovation, security,
          exposure, or other exclusive privileges by User or anybody utilizing
          User's record.
        </p>
        <h2>Jurisdiction-</h2>
        <p>
          The Terms of Use will be administered by the laws of India, and the
          courts at Bengaluru, India will have restrictive purview concerning
          any question emerging hereunder
        </p>
        <h2>International Use-</h2>
        <p>
          The Site is controlled and worked from inside India. Academor makes no
          portrayal that the Site is fitting or accessible in areas outside of
          India. The individuals who decide to get to the Site or use our
          administrations from different areas do as such at their own danger
          and are answerable for consistency with material laws.
        </p>
        <h2>General-</h2>
        <p>
          The disappointment of Academor to implement any right or arrangement
          of these Terms of Use won't comprise a waiver of future implementation
          of that right or arrangement. The waiver of any such right or
          arrangement will be compelling provided that it is recorded as a hard
          copy and endorsed by an appropriately approved agent of Academor.
          Besides as explicitly set out in these Terms of Use, the activity by
          one or the other party of any of its cures under these Terms of Use
          will be without bias to its different cures under these Terms of Use
          or in any case. In the event that under any circumstance a court of
          equipped locale, as relevant, of able ward observes any arrangement of
          these Terms of Use invalid or unenforceable, that arrangement will be
          authorized to the greatest degree allowable and different arrangements
          of these Terms of Use will stay in full power and impact.
        </p>
        <h2>Miscellaneous-</h2>
        <p>
          Besides as explicitly given in these Terms of Use, there will be no
          outsider recipients to the Terms of Use. Academor will reserve the
          privilege to dole out its freedoms or represent any of its obligations
          under these Terms of Use to a subsidiary or regarding a consolidation,
          union, or rearrangement of Academor for the offer of generously the
          entirety of its resources.
        </p>
        <p>
          If any arrangement of this Agreement, or the application thereof,
          becomes or is pronounced by a court of an able ward to be illicit,
          void, or unenforceable, the rest of this Agreement will proceed in
          full power and impact and the utilization of such arrangement to
          different people or conditions will be deciphered so as sensibly to
          impact the plan of the gatherings hereto.
        </p>
        <p>
          This Agreement comprises the whole understanding among Academor and
          User with respect to the topic about, and overrides any past game
          plan, comprehension, or arrangement between the gatherings, composed
          or oral, connecting with the topic concerning this.
        </p>
        <p>
          In any case anything to the opposite in this, Academor won't be
          expected to take responsibility for any postponement or disappointment
          in execution or non-accessibility of the administrations coming about,
          straightforwardly or in a roundabout way, from demonstrations of
          nature, occasions, exclusions, mishaps or causes past its sensible
          control, including, yet not restricted to, web disappointment,
          organization or PC hardware disappointments, telecom gear
          disappointment, electrical power disappointments, strikes, lock-outs
          or other modern debates, work questions, riots, rebellions, common
          unsettling influences, deficiencies of work or materials, fires,
          floods, storms, blasts, breakdown of plant or apparatus,
          demonstrations of God, war, legislative activities or orders, requests
          of homegrown or unfamiliar courts or councils.
        </p>
        <h2>Online Course</h2>
        <p>Scope-</p>
        <p>
          "The expenses that we charge the understudies are just for preparing
          them through live classes and not the entry-level position itself.
          Academor doesn't charge any sum for the temporary position project
          stage as it is totally free."
        </p>
        <p>Procedure-</p>
        <p>Programs:</p>
        <p>Course Programs-</p>
        <p>Self-Guided/Mentor Led Course Program:</p>
        <p>
          All self-guided or Mentor Led programs are non-refundable and
          non-transferable under any conditions.Minimum discounts will be
          provided that ACADEMOR drops the total program.
        </p>
        <p>Change Of Course-</p>
        <p>
          Academor permits you to shift your direction to an elective course
          just once utilizing the accompanying rules:
        </p>
        <p>
          Academor is advised to be recorded as a hard copy somewhere around
          multi-week before the beginning of the first course.
        </p>
        <p>
          The elective course has seats accessible and will be done within
          multi-month of the first-course finish date.
        </p>
        <p>
          Assuming that changing to a course of higher worth, understudies are
          needed to pay the sum distinction. Notwithstanding, assuming changing
          to a course of lower esteem, Academor isn't responsible to pay the sum
          distinction. A handling charge of Rs. 1000/ - should be paid inside 3
          days later Academor acknowledges a shift in direction demand, bombing
          which course change solicitation will be dropped and no further
          solicitation will be engaged.
        </p>
        <p>
          Direction shift is permitted just a single time during the program.
        </p>
        <p>Process For Change Of Course-</p>
        <p>Contact support@academor.com with your direction shift demand.</p>
        <p>
          Once endorsed, top off the "Direction shift Form" and submit it within
          3 workdays.
        </p>
        <p>
          Pay the handling expenses + contrast in course costs (if appropriate)
          and present the receipt to " support@academor.com ".
        </p>
        <p>
          In 5-7 workdays, you will get an affirmation for the direction shift
          demand.
        </p>
        <h2>Reschedule-</h2>
        <p>
          Rescheduling by Academor Edutech is represented by the accompanying
          rules:
        </p>
        <p>
          On the off chance that a course doesn't start inside 10 work long
          periods of distributed initiation date, an understudy is qualified for
          either a full discount or can decide to join an alternate
          cluster/course.
        </p>
        <p>
          Academor will put forth all potential attempts to direct a course. Be
          that as it may, in case of a course being rescheduled because of
          unanticipated postponements on our end, Academor EduTech is just
          liable for the expense of the course. Any movement, strategies, or
          individual costs brought about because of this program will not be
          discounted.
        </p>
        <p>
          Rescheduling demands by clients are represented by the accompanying
          rules:
        </p>
        <p>
          The reschedule demand must be made somewhere around 7 work days before
          the initiation of the course.
        </p>
        <p>
          The rescheduled date ought to be within 90 days of the underlying
          initiation date.
        </p>
        <p>Rescheduling won't permit the client to shift their direction.</p>
        <p>The reschedule is dependent upon the accessibility of seats.</p>
        <p>
          A rescheduling charge of Rs. 1000/ - should be paid within 5 workdays
          from the date of endorsement
        </p>
        <h2>Process for Rescheduling of Course:</h2>
        <p>
          Contact support@academor.com with your course rescheduling demand.
        </p>
        <p>
          Once endorsed, top of the "Reschedule Course Form" and submit it
          within 3 workdays.
        </p>
        <p>
          Pay the handling charges + distinction in course costs (if pertinent)
          and present the receipt to " support@academor.com "In 5-7 workdays you
          will get an affirmation for the course reschedule demand.
        </p>
        <p>
          Assuming that the rescheduled course is of higher worth, understudies
          are needed to pay the sum distinction. Notwithstanding, assuming
          changing to a course of lower esteem, Academor isn't obligated to pay
          the sum distinction. A handling charge of Rs. 1000/ - should be paid
          inside 3 days later Academor acknowledges rescheduled demand, bombing
          which reschedules solicitation will be dropped and no further
          solicitation will be engaged.
        </p>
        <h2>Cancellation-</h2>
        <p>
          Academor Edutech maintains whatever authority is needed to drop any
          meeting or change the area of the meeting for reasons, for example,
          Instructor inaccessibility, inadequate members, or power majeure
          occasions like seismic tremors, fire, flood, riots, time limit, bandh,
          fight, strike, common agitation and so forth Kindly note our undoing
          strategy is represented by the accompanying:
        </p>
        <p>
          In the event that Academor Edutech drops a studio/course/meeting, a
          100% discount will be paid to the members.
        </p>
        <p>
          On the off chance that Academor Edutech changes the area of the
          occasion, courses of action of a comparative foundation as recently
          concluded will be executed.
        </p>
        <p>
          No close-to-home costs because of the difference in the area will be
          borne by Academor Edutech under any conditions.
        </p>
        <p>
          Academor Edutech maintains whatever authority is needed to either
          oblige understudies in a comparable foundation as initially chosen or
          pay remuneration Academor Edutech considers fit for something similar.
        </p>
      </section>
      </div>
    </div>
    </>
  );
}

export default Termsandconditions;

import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import carImg from "../../../assets/Allcoureses/car design.png"
import Section3 from './../../../components/innerPage/section3/section3';

function CarDesign() {
      //section1Data
  const section1Data = {
    sectImg :carImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Car Design</span> with the best instructors
      </h1>
    ),
    description:
     "Activate your imagination with our Car Design Course. Learn from industry professionals, master automotive sketching and 3D modeling, and transform your passion into innovative automobile designs. Enroll now for unlimited opportunities!",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "The functionality of an automotive structure is significantly influenced by its design. To advance your knowledge and become an excellent designer, have a thorough understanding of the fundamentals of car design.",
    points : [
        "Surface Design",
        "Automotive Shape Design",
        "AutoDesk Inventor",
        "Wire and Metal Design",
    ]
  };
     //section 3
     const section3Data = [
      {
        topic: " Introduction to Car Designing",
        subTopics: [
          "Here you will learn about the Evolution of Cars, the Classification of Modern Cars and the Basic Structure of a Car.",
        ],
      },
      {
        topic: "CATIA V5 Sketch Tracer and Surface Design",
        subTopics: [
          "In this section, you’ll learn about the Introduction to CATIA V5, Adjusting Workspace and environment, Converting concept sketches to surface design, Sketch Tracer paintings, Importance of Free styling, CATIA V5 Freestyle Surfacing, Wireframe and Surface Design Operations and case studies.",
        ],
      },
      {
        topic: " CATIA V5 Sheet Metal Design",
        subTopics: [
          "This module deals with Sheet Metal Parameters, GUI of Sheet Metal Design, Recognizing the Sheet metals, Generating Walls, Stamping and Cutting Sheet Metals and case studies.",
        ],
      },
      {
        topic: "ALIAS Automotive Wire Design",
        subTopics: [
          "Here, you will be taught about the Fundamentals of Autodesk ALIAS, Automotive GUI and Display Controls, Layer Management, Selecting and Grouping imported car works, Developing NURBS, Developing Geometry, Understanding Class A and Class B, Surfacing, case studies and project work.",
        ],
      },
      {
        topic: "ALIAS Automotive Shape Design",
        subTopics: [
          "This section deals with the introduction of Free form blend, Skin Surface and Sketch Surface, Draft Surface, Mono-Rail and Bi-Rail surfaces.",
        ],
      },
      {
        topic: " ALIAS Automotive Class A Surfacing and Reverse Engineering",
        subTopics: [
          "This module deals with Technical Surfacing Evaluations, tolerance at surface patches, and how it governs control points.",
    
        ],
      },
      {
        topic: " CATIA V5 Part Design",
        subTopics: [
          "This section is about Designing GUI of Part Design, Creating 3D models, and modifying 3D Designs.",
        ],
      },
      {
        topic: " CATIA V5 Assembly Design",
        subTopics: [
          "This section covers Assembly Requirements, while also defining constraints and interference checks. The section also includes various exercises.",
        ],
      },
      {
        topic: "CATIA V5 Drafting",
        subTopics: [
          "This module will teach you how the procedures of creating a draft with an introduction for the same. You will also learn how to create the GUI of a Draft. You will understand about Part Drafting and Assembly Drafting.",
        ],
      },
      {
        topic: " AutoDesk Inventor Stress and Modal Analysis",
        subTopics: [
          "In this module you will get to know different types of Engineering, Meshing and it’s factors, GUI of Autodesk Inventor, Stress Analysis, Part Drafting and Modal Analysis.",
        ],
      },
    ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default CarDesign

import React from "react";
// import "./sectionUnderlineHeading.css";

function SectionUnderlineHeading({
  heading,
  align = "center",
  customClass='',
  marginLeft='ml-auto',
  marginRight='mr-auto'
}) 

 
{
  return (<>
    <h1
      className={`relative xl:text-[1.75rem] md:text-xl w-fit ${marginLeft} ${marginRight} font-bold text-${align} ${customClass}`}
    >
      {heading}
      <span
        className={"absolute left-0 bottom-[-5px] w-6/12 h-0.5 bg-[#EF0000]"}
      ></span>
    </h1>
 </> );
}

export default SectionUnderlineHeading;

import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import hybridImg from "../../../assets/Allcoureses/hybrid vehicles.png"
import Section3 from './../../../components/innerPage/section3/section3';

function HybridElectricVehicles() {
      //section1Data
  const section1Data = {
    sectImg : hybridImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Hybrid Electric Vehicles</span> with the best instructors
      </h1>
    ),
    description:
     "Civil course covering hybrid electric vehicles in depth for civil engineers. Considers urban planning, infrastructure, pricing, and environmental impact. To design sustainable transportation systems, combine theoretical knowledge with practical experience.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Engineering concepts and your engineering designs and ideas to build an electric hybrid vehicle.",
    points : [
      "Fundamentals of basic engines and their working.",
      "Conceptual idea and hybrid electrical systems and their power components.",
      "Control system, engineering concepts with expert trainers."
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "introduction",
      subTopics: [
        "understanding of IC engine",
        "building a HEV",
      ],
    },
    {
      topic: "hybrid and electric vehicle systems",
      subTopics: [
        "learn various components into making HEVs",
        "various system involved",
      ],
    },
    {
      topic: "battery technology and electric motors",
      subTopics: [
        "brief description of batteries and types and parameters.",
        "Understanding of motors",
      ],
    },
    {
      topic: "electric power components",
      subTopics: [
        "description of concepts of motors",
        "description of generators",
        "power electronics",
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default HybridElectricVehicles

import React from 'react';
import { Helmet } from 'react-helmet';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import aiimg from "../../../assets/Allcoureses/artificial intelligence.png";
import Section3 from './../../../components/innerPage/section3/section3';

function AI() {
  // section1Data
  const section1Data = {
    sectImg: aiimg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Artificial Intelligence</span> with the best instructors
      </h1>
    ),
    description:
      "Our course examines AI's fundamentals and applications. Project-based instruction in machine learning, neural networks, and natural language processing. Industry-relevant abilities for the technological landscape of tomorrow",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description: "A major and vast concept to work to invent and create intelligence in artificial machines.",
    points: [
      "Learn simulation of human intelligence in machines (computers).",
      "Concepts of machine learning, AI itself, practical concepts and many more.",
      "Detailed teachings of topics such as neural network, deep learning, and reinforcement learning.",
    ],
  };

  // section 3
  const section3Data = [
    {
      topic: "Implementing association rule mining",
      subTopics: [
        "Association rules and its uses",
        "Recommendation engine",
        "Recommendation use-case",
        "Case study",
      ],
    },
    {
      topic: "Decision tree classifier",
      subTopics: [
        "Basics of building decision trees",
        "Classification and use cases",
        "Confusion matrix",
        "Case study",
      ],
    },
    {
      topic: "Random forest classifier",
      subTopics: [
        "Random forest",
        "Features of random forest",
        "Out of box error and variable importance.",
        "Case study",
      ],
    },
    {
      topic: "Support vector machines",
      subTopics: [
        "Introduction to SVMs",
        "Vectors overview",
        "Decision surfaces",
        "The kernel trick",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Artificial intelligence Internships | Online AI internships</title>
        <meta name="description" content="With Academor AI internships online training course certifications, set out on a revolutionary adventure into the world of artificial intelligence." />
        <meta name="keywords" content="Artificial Intelligence Internships online courses, Artificial intelligence Internship programs, Artificial intelligence courses, Online Artificial intelligence certifications, Artificial intelligence courses near me, Online Artificial intelligence internships" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default AI;

import React, { useState } from "react";
import "./contactUs.css"; // Updated CSS file name
import updatedContactIcon from "../../assets/footer/phone.svg";
import updatedMail from "../../assets/footer/mail.svg";
import updatedLocation from "../../assets/footer/location.svg";
import updatedLinkdin from "../../assets/footer/linkedin.svg";
// import updatedFacebook from "../../assets/footer/facebook.svg";
import updatedYouTube from "../../assets/footer/youTube.svg"
import updatedPinterest from "../../assets/footer/pinterest.svg"
import updatedTwitter from "../../assets/footer/twitter.svg";
import updatedInsta from "../../assets/footer/instagram.svg";
import updatedGrid1 from "../../assets/contactUs/RepeatGrid.svg";
import updatedContactAnimation from "../../assets/contactUs/75948.json";
import Lottie from "react-lottie";
import Thanks from "./Thanks"; 
import { Helmet } from 'react-helmet';

function Contact() {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [showThanksPopup, setShowThanksPopup] = useState(false); // State to control the Thanks popup

  const updatedContactUsAnimation = {
    loop: true,
    animationData: updatedContactAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbye3adI0adpRd2Tn3yEyceceEabkqk2zXno_sVz9LXm4gCmzZFFhUx5MlGOGYDQoEhH/exec",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        console.log("Form submission successful");
        setSubmissionStatus("success");
        form.reset();
        setShowThanksPopup(true); // Show the Thanks popup upon successful form submission
      } else {
        console.log("Form submission failed");
        setSubmissionStatus("error");
      }
    } catch (error) {
      console.log("Error occurred:", error);
      setSubmissionStatus("error");
    }
  };

  return (
    <>
      <Helmet>
        <title>Contact us- Enquiry internship possibilities for freshers</title>
        <meta name="description" content="Have a question or a concern internship possibilities? Fill out the form and wait for us Our team is also happy to hear from you!"/>
        <meta name="keywords" content="Enquiry internship possibilities, how do I know about internship"/>
      </Helmet>
      <div className="updatedContactMain">
      <div className="updatedContactUsAnimation">
          <Lottie options={updatedContactUsAnimation} />
        </div>
        <div className="updatedContactDetails">
          <img src={updatedGrid1} alt="GridDots" />
          <h1 className="updatedContactUs">Contact Us</h1>
          <p>
            Have a question or a concern for us?<br></br>
            Fill out the form and wait for us to contact you about it! Our team
            is also happy to hear from you!
          </p>
          <div className="updatedContactSub1">
            <a href="tel:90199 23649" className="updatedEachContact">
              <img src={updatedContactIcon} alt="contact-icon" />
              <p>8310186079</p>
            </a>
            <a target={"_blank"} href="mailto:support@academor.com" className="updatedEachContact">
              <img src={updatedMail} alt="mail-icon" />
              <p> support@academor.com</p>
            </a>
            <a
              target={"_blank"}
              href="https://maps.app.goo.gl/4Vytq9JECZhmiNWr7"
              className="updatedEachContact"
            >
              <img src={updatedLocation} alt="location-icon" />
              <p>
                Galactic Spaces, 14th A Cross Rd, HSR Layout Sector-6, Bengaluru, Karnataka 560102
              </p>
            </a>
          </div>
          <div className="updatedFollowUs">
            <p>Follow Us:</p>
            <a target={"_blank"} href="https://www.linkedin.com/company/academor/">
              <img src={updatedLinkdin} alt="linkdin" />
            </a>
         
            {/* <a target={"_blank"} href = "https://www.facebook.com/profile.php?viewas=100000686899395&id=100084745865313">
              <img src = {updatedFacebook}  alt ='facebook'/></a>
            </a> */}
            <a target={"_blank"} href="https://twitter.com/academor_22">
              <img src={updatedTwitter} alt="twitter" />
            </a>
            <a target={"_blank"} href="https://in.pinterest.com/academor/">
              <img src = {updatedPinterest}   alt = 'pinterest'/>
            </a>
            <a target={"_blank"} href = "https://www.youtube.com/@AcademorOfficial">
              <img src = {updatedYouTube}   alt = 'youTube'/>
            </a>
            <a target={"_blank"} href="https://instagram.com/academor?igshid=YmMyMTA2M2Y=">
              <img src={updatedInsta} alt="instagram" />
            </a>
          </div>
        </div>
        <div className="updatedContactForm">
          <div className="updatedContactNameMob">
            {/* <h1 className="updatedContactUs">Contact Us</h1> */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="updatedContactName">
              <label className="updatedInputLabel">Full Name</label>
              <input
                className="updatedContactUsInput"
                type="text"
                placeholder="Full Name"
                name="Full Name"
              required/>
            </div>

            <label className="updatedInputLabel">Email Address</label>
        <input
          className="updatedContactUsInput"
          type="email"
          placeholder="Email"
          name="Email"
          required/>

        <label className="updatedInputLabel">Phone Number</label>
        <input
          className="updatedContactUsInput"
          type="tel"
          placeholder="Contact Number"
          name="Contact Number"
          required/>
        <label className="updatedInputLabel">Interested Domain</label>
        <input
          className="updatedContactUsInput"
          type="text"
          placeholder="Your Interested Domain"
          name="Interested Domain"
          required/>
         <label className="updatedInputLabel">Are you a:</label>
        <select
          className="updatedContactUsInput"
          name="Student Type"
         >
          <option value="new">New Student</option>
          <option value="existing">Existing Student</option>
        </select>
        <label className="updatedInputLabel">Message</label>
        <textarea
          type="text"
          className="updatedContactUsInput updatedTextarea"
          placeholder="Get in touch with us"
          name="Message"
        />

            <button className="updatedBtnPrimary" type="submit"><b>SUBMIT</b></button>
          </form>
          {submissionStatus === "error" && <p>An error occurred. Please try again later.</p>}
          <img src={updatedGrid1} alt="GridDots" />
        </div>
        {showThanksPopup && <Thanks onClick={() => setShowThanksPopup(false)} />}
      </div>
        
    </>
  );
}
export default Contact;

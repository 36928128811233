import React from 'react'
import "./studentTestimonialCard.css"
import leftQoute from "../../assets/icons/left-qoute.svg"
import rightQoute from "../../assets/icons/right-qoute.svg"
import darkGreyStar from "../../assets/icons/darkGreyStar.svg"
import studentImage from "../../assets/icons/student-image.png"
import redstar from "../../assets/icons/redStar.svg"



function StudentTestimonialCard({feedback="",studentName}) {
  return (
    <div className = 'bg-[#fff] p-7 pb-20 rounded-xl shadow-[7px_7px_9px_0px_#00000029;] h-72 overflow-y-auto'>
        <div className = 'p-6 relative border-solid border border-[#FFA2A2]  shadow-[0_3px_9px_#00000029] '>
          
           <img className='quote absolute top-0 left-[5%] -translate-y-[50%]' src={leftQoute} alt ='left-qoute'/>
           <img className='quote absolute bottom-0 right-[5%] translate-y-[50%]' src={rightQoute} alt ='right-qoute'/>
           <p className='lg:text-[1rem] pb-3 text-left  paragraph'>{feedback}</p>
            <div className='flex gap-[1px] justify-end'>
            <img src ={redstar} alt = 'star'/>
            <img src ={redstar} alt = 'star'/>
            <img src ={redstar} alt = 'star'/>
            <img src ={redstar} alt = 'star'/>
            <img src ={redstar} alt = 'star'/>
            </div>
            <p className='lg:text-[1.3rem] pb-3 text-left  paragraph'>{studentName}</p>
        </div>
         {/* <img className='student-image ' src = {studentImage} alt = 'student-image'/> */}
    </div>
  )
}

export default StudentTestimonialCard
import React from 'react'

function Sec1Card({img1,img2,className=''}) {
  return (
    <div className={`absolute z-10 w-4/12 flex flex-col ${className}`}>
    <img className="hover:scale-[1.1] transition-all"  src={img1} alt ='img-1'/>
    <img className="relative -z-10 translate-x-[16px] translate-y-[-81%]" src = {img2} alt='img-2'/>
    </div>
  )
}

export default Sec1Card
import React from "react";
import "../computerScience/computerScience.css";
import Section1 from "../../../components/departmentPage/section1/section1";
import Section2 from "../../../components/departmentPage/section2/section2";
import DepartmentCarousel from "../../../components/departmentPage/departmentCarousel/departmentCarousel";
//images
import bioVideo from "../../../assets/departmentLanding/biotechnology/bioVideo.mp4"
import geneticengineering from "../../../assets/departmentLanding/biotechnology/genetic engineering.jpg"
import nanoImg from "../../../assets/departmentLanding/biotechnology/nanoscience.png"
import webbioinfo from "../../../assets/departmentLanding/biotechnology/web bio info.png"
import { Helmet } from 'react-helmet';

function Biotechnology() {
      //  section 1 data
  const section1Data = {
    heading: (
      <h1 className="multiple-color two">
        <span>Academor:</span> Excellence in courses, led by exceptional instructors.
      </h1>
    ),
    url: bioVideo,
    content: `Our Biotechnology Courses are designed to unlock the potential of aspiring students in the fascinating realm of life sciences and technology. The courses are thoughtfully curated by industry experts to provide all- compassing understanding of biotechnology's applications and advancements. Throughout the program, students will gain hands-on experience and theoretical knowledge, preparing them for exciting career opportunities in the field of biotechnology.`,
  };

  //section2 courses data
  const heading = "Biotechnology"
  const courseArr = [
    { courseName: "Nano Technologies", rating: "6", link: "/NanoTechnologies", courseImg:nanoImg},
    { courseName: "Genetic Engineering", rating: "6", link: "/GeneticEngineering", courseImg:geneticengineering},
    { courseName: "Bio Informatics", rating: "6", link: "/BioInformatics", courseImg:webbioinfo},

  ];
      //section 3 related departments
      const relatedDepartments = [
        {
          departmentName: "Computer Science",
          totalCourses : "8",
          departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod`,
          link: "/computer-science"
        },
        {
          departmentName: "Management",
          totalCourses : "6",
          departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod`,
          link: "/Management"
        },
        {
          departmentName: "Electronics And Communication",
          totalCourses : "5",
          departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod`,
          link: "/electronicsAndCommunication"
        },
        {
          departmentName: "Civil And Mechanical",
          totalCourses : "2",
          departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod`,
          link:"/civilAndMechanical"
        },
      ]
  return (
    <>
      <Helmet>
        <title>Biotechnology programs online | Biotechnology Courses India</title>
        <meta name="description" content="Biotechnology Courses are designed to unlock the potential of aspiring students in the fascinating life sciences & technology."/>
        <meta name="keywords" content="Online biotechnology courses for students, Biotechnology programs in India, science & technology programs for students, online courses for biotechnology students"/>
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 courseArr={courseArr} heading={heading}/>
      <DepartmentCarousel relatedDepartments={relatedDepartments}/>
    </>
  );
}

export default Biotechnology;

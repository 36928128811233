import React from 'react';
import './section1.css';
import Lottie from "react-lottie";
// import sectImg from '../../../assets/innerPage/sec1Img.png';
import sec1Animation from '../../../../src/animations/innerSec1Animation.json';


function Section1({heading,description,link,sectImg}) {
    const innerSec1AnimationOption = {
        loop: true,
        animationData: sec1Animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice",
        },
      };
  return (
     <section className='section1Main'>
        <div className='section1Content'>
            {heading}
            <p>{description}</p>
            <a href={link} className='btn-primary'>Explore Courses</a>
        </div>
        <div className='section1Image'>
         <img src={sectImg} alt='sec1Image'/>
         <div className='innerSec1lottie'>
         <Lottie options={innerSec1AnimationOption} />
         </div>
        </div>
     </section>
  )
}

export default Section1
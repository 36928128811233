import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import arvrImg from "../../../assets/Allcoureses/ar-vr.png"
import Section3 from './../../../components/innerPage/section3/section3';
import { Helmet } from 'react-helmet';

function Arvr() {
          //section1Data
  const section1Data = {
    sectImg:arvrImg,
    heading: (
      <h1 className="multiple-color">
        Use <span>Augmented and virtual reality</span> to enhance your creativity and sense of imagination.
      </h1>
    ),
    description:
     "Learn about the newest dimensional content technologies that have dramatically improved user experience. To meet the rising demand for Virtual Reality (VR), Augmented Reality (AR), and Mixed Reality (MR) content, educate yourself utilizing the most recent hardware and software.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "This course provides you with end-to-end training in Dimensional Content for Augmented and Virtual Reality. We offer a specialization module on Virtual Reality that covers topics like Unity, Vuforia, app development, and VR games.",
    points : [
        "Unity 3D",
        "Scripting",
        "Animations",
        "Game Objects",
    ]
  };
     //section 3
     const section3Data = [
      {
        topic: "Introduction to Mixed Reality (AR/VR)",
        subTopics: [
          "Here, you will be introduced to different versions of realities, i.e., augmented, virtual and mixed reality, and the history of these three. You will also get to know other fundamentals of mixed reality."
        ],
      },
      {
        topic: "Introduction to Unity 3D",
        subTopics: [
          "In this section, learn about different views and interfaces of Unity 3D. You will also understand Hand Tool and Playthrough Mode.",
        ],
      },
      {
        topic: "Game Objects",
        subTopics: [
        "This module makes you understand dimensional and coordinate systems for 3D modeling, World versus Local Coordinates, and Game Objects."
        ],
      },
      {
        topic: "Models, Materials and Textures",
        subTopics: [
          "Introduction to Models and 3D objects, importing Models, understanding textures, shaders, and materials.",
        ],
      },
      {
        topic: " Lights and Camera",
        subTopics: [
          "In this module, you will understand lights, spotlights, directional Lights, halos, and cookies. Then, you will get to know about cameras and layers and the anatomy of a camera.",
        ],
      },
      {
        topic: "Scripting",
        subTopics: [
          "You will understand scripts, conditional statements, and operators. You will also be introduced to methods, the anatomy of processes, specific vital inputs, and components.",
        ],
      },
      {
        topic: "Prefabs",
        subTopics: [
          "In this module, there would be an introduction to Prefabs. You will also get to know about, Structure, Working, and Deployment of Prefabs through codes.",
        ],
      },
      {
        topic: "User Interface",
        subTopics: [
          "Learn fundamental UI Principles and Elements, including text, anchors and buttons, screen-space elements, and world space.",
        ],
      },
    ];
  return (
    <>
    <Helmet>
        <title>Augmented and virtual reality courses | AR & VR online course</title>
        <meta name="description" content="Learn about the Augmented and virtual reality courses technologies that have dramatically improved user experience.AR & VR"/>
        <meta name="keywords" content="Augmented and virtual reality courses, Virtual Reality (VR) VS Augmented Reality (AR), AR & VR online course with certificate, AR & VR online course for students"/>
      </Helmet>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default Arvr
import React from "react";
import {Link} from "react-router-dom"
import "./courseCard.css";
import greyStar from "../../../assets/icons/greyStar.svg";
import redStar from "../../../assets/icons/redStar.svg";
import defaultImg from "../../../assets/departmentLanding/default-course-card-img.png";
import time from "../../../assets/departmentLanding/time.svg";
import mentor from "../../../assets/departmentLanding/mentor.svg";
import live from "../../../assets/departmentLanding/live.svg";

function CourseCard({ courseName, rating, link, courseImg = defaultImg }) {
  const arr = [1, 2, 3, 4, 5];
  const pointsArr = [
    { img: time, point: "Customized Timespan" },
    { img: mentor, point: "1-1 Mentorship & Job Support" },
    { img: live, point: "Live Sessions" },
  ];
  return (
    <div className="w-[100%] md:w-[45%] xl:w-[31%] bg-white  p-[20px] rounded-[10px] border-solid border-[1px] border-[#E6E6E6] shadow-[0px_3px_9px_#00000029]">
      <div className="flex items-center justify-end gap-[2px] pb-[10px]">
        {arr?.length > 0 &&
          arr.map((item) => {
            return (
              <img
                className="w-[10px] h-[10px]"
                alt="star"
                key={item}
                src={item < rating ? redStar : greyStar}
              />
            );
          })}
      </div>
      <div className="flex items-start ">
        <img className="block w-[45%] max-h-[150px] mob:max-h-[200px]" alt="course-img" src={courseImg} />
        <section className="w-[55%] pl-[14px]">
          <h1 className='font-[800] text-[15px] mob:text-[18px]'>{courseName}</h1>
          <div className = 'flex flex-col gap:[10px_0px] mob:gap-[14px_0px]'>
           {pointsArr?.length>0 && pointsArr.map((item,i)=>{
             return  <div className="flex items-center gap-1 text-[10px] mob:text-[12px]">
                <img className="w-[24px] h-[24px] block" alt='icon' src = {item.img}/>
                <p>{item.point}</p>
             </div>
           })}
          </div>
            <Link className="inline-block no-underline text-[#EF0000] text-[10px] mob:text-[12px] rounded-[4px] p-[4px_22px] mob:p-[5px_25px] border-[#EF0000] border-solid border-[1px]" to ={link}>View Details</Link>
        </section>
      </div>
    </div>
  );
}

export default CourseCard;

import React from "react";
import Carousel from "../../carousel/carousel";
import PopUpModal from "../../popupModal/PopUpModal";
import PricingPlan from "../../pricingPlan/pricingPlan";
import SectionUnderlineHeading from "../../sectionUnderLineHeading/sectionUnderlineHeading";
import "./pricingPlanSection.css";
function PricingPlanSection() {
  const [showModal,setShowModal] = React.useState(false)
  const carouselOptions = {
    margin: 50,
    nav: true,
    dots: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
    },
  };

  const pricingData = [
   {
      type: "Self Paced",
      price: "₹ 4500",
      pointsArr: [
        { content: "20+ Hours of video content access", valid: true },
        { content: "1 minor & 1 major Project", valid: true },
        { content: "2 months internship", valid: true },
        { content: "6 month LMS access", valid: true },
        { content: "Certificate for course completion", valid: true },
        { content: "Co-Branded Internship certificate", valid: false },
      ],
      link: "#",
    },
    {
      type: "Blended",
      price: "₹ 5500",
      pointsArr: [
        { content: "20+ Hours of video content access", valid: true },
        { content: "1 Live Class per week", valid: true },
        { content: "1 Minor & 1 Major project", valid: true },
        { content: "2 months internship", valid: true },
        { content: "6-month LMS access", valid: true },
        { content: "Certificate for course completion", valid:  true },
        { content: "Co-Branded Internship certificate", valid:  true },
      ],
      link: "#",
    },
    {
      type: "Mentor Led",
      price: "₹ 7500",
      pointsArr: [
        { content: "16+ Hours of Live mentor training", valid: true },
        { content: "20+ Hours of video content access", valid: true },
        { content: "1 Minor & 1 Major", valid: true },
        { content: "2 months internship", valid: true },
        { content: "6 Months LMS Access", valid: true },
        { content: "Certificate for course completion", valid: true },
        { content: "Co-Branded Internship certificate", valid: true },
        
      ],
      link: "#",
    },
    {
      type: "Advanced",
      price: "₹ 10000",
      pointsArr: [
        { content: "18+ Hours of Live mentor training", valid: true },
        { content: "20+ Hours of video content access", valid: true },
        { content: "2 minor & 2 major Project", valid: true },
        { content: "3 months internship", valid:  true },
        { content: "6 month LMS access", valid:  true },
        { content: "One to One Doubt Session", valid: true},
        { content: "Job Assistance", valid: true },
        { content: "Certificate for course completion", valid: true},
        { content: "Co-Branded Internship certificate", valid: true },
        
      ],
      link: "#",
    },
  ];
  return (
    <div className="p-[3%_10%_5%]">
      <SectionUnderlineHeading heading="Pricing Plan" />
      <Carousel customClass="pricing-Carousel" options={carouselOptions}>
        {pricingData?.length>0 && pricingData.map((item,i)=>{
            return <PricingPlan key = {i} {...item} onClick = {()=>setShowModal(true)} />
        })}
      </Carousel>
     {showModal &&  <PopUpModal
        onClick={()=>setShowModal(false)}
       />}
    </div>
  );
}
export default PricingPlanSection;

import React from "react";
import "./section1.css";
import ReactPlayer from "react-player";
import thumbnail from "../../../assets/departmentLanding/thumbnail.png";
import Lottie from "react-lottie";
import playBtnAnimation from "../../../animations/play.json";
import playBtn from "../../../assets/departmentLanding/playBtn.svg";
function Section1({heading,content,url}) {
  const playBtnOptions = {
    loop: true,
    animationData: playBtnAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className="flex flex-wrap items-center p-[10%] mob:p-[5%_10%] md:p-[4%_7%_5%] dept-sec-1">
      <section className="w-[100%] md:w-[55%]">
        {heading}
        <p className="p-[5%_0%_10%] md:p-[14px_5%_0px_0px] font-[300] text-[14px] md:text-[16px] text-[#E3E3E3]">
           {content}
        </p>
      </section>
      <div className="w-[100%] p-[5%_0%_0%-0%] md:p-0 md:w-[45%]">
        <div className="relative m-auto w-[90%] h-[200px] md:h-[320px] rounded-xl">
          <ReactPlayer
            className="absolute top-0 left-0 rounded-xl"
            height={"100%"}
            width={"100%"}
            controls={true}
            light={thumbnail}
            url={url}
            playing={false}
            playIcon={
              <div className="relative w-[100px] h-[100px]">
                <img className="w-[80%] relative z-10 h-[80%]" alt="btn" src={playBtn} />
                <div className="absolute -z-10 w-[100%] h-[100%]">
                <Lottie options={playBtnOptions} />
                </div>
               
              </div>
            }
          />
        </div>
      </div>
    </div>
  );
}

export default Section1;

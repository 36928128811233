import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import financeImg from "../../../assets/Allcoureses/finance.png"
import Section3 from './../../../components/innerPage/section3/section3';

function Finance() {
     //section1Data
  const section1Data = {
    sectImg:financeImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Finance</span> with the best instructors
      </h1>
    ),
    description:
     "Our finance course provides a thorough introduction to key financial concepts, such as budgeting, investing, the financial markets, and risk management. Participants will acquire a solid comprehension of financial principles, enabling them to make informed decisions, effectively manage personal finances, and navigate the complexities of the global financial system.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Discipline that deals with money, currency and capital assets that disjoints the area of economics.",
    points : [
      "Learn about investments and basics of finance.",
      "Handling of finance, concepts of microfinance and management.",
      "Venture capitals and their function in the organization."
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "investment banking services",
      subTopics: [
       "variables, data types, loops",
       "cost effective management of merchant banking services"
      ],
    },
    {
      topic: "SEBI regulations and depository system",
      subTopics: [
       `Learning objectives and adjectives of a
       depository system`,
       `NSDL, CDSL`,
       `Depository partners`,
      ],
    },
    {
      topic: "Case study",
      subTopics: [
        `Solving practical difficulties`,
        `Application and limitations`,
        `Investment banking`,
        `Advantages of depository system`
      ],
    }
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default Finance

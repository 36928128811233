import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import digiImg from "../../../assets/Allcoureses/digital marketing.png"
import Section3 from './../../../components/innerPage/section3/section3';

function DigitalMarketing() {
     //section1Data
  const section1Data = {
    sectImg:digiImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Digital Marketing</span> with the best instructors
      </h1>
    ),
    description:
     "Our digital marketing course provides a comprehensive overview of essential online marketing strategies and tools. Through interactive sessions, real-world examples, and hands-on exercises, participants will gain valuable insights into SEO, social media marketing, email campaigns, analytics, and more, enabling them to succeed in the dynamic world of digital marketing.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "A greatly growing discipline that intersects the field of market and digital application in the growth support of organizations. Learn to advertise through digital channels using great methods you learn with us.",
    points : [
      "Learn concepts and work with Search Engine Operations and promotions on websites and web browsers.",
      "Advertising and promotions on social media marketing of customer brands and organizations.",
      "Become a professional in web analytics with the best guidance from our experienced professionals."
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Search engine optimization",
      subTopics: [
        "Working of search engine",
        "App store optimization",
        "Working with backlink audit and potential forums"
      ],
    },
    {
      topic: "Search engine marketing",
      subTopics: [
        "Keyword research and planning",
        "Remarketing, RLSA and dynamic targeting",
        "Youtube advertising",
        "Shopping PLA Ads and smart display campaigns"
      ],
    },
    {
      topic: "Social media marketing",
      subTopics: [
        "Facebook marketing",
        "Instagram marketing",
        "Twitter marketing",
        "Various other social media platforms, hootsuite, buffer."
      ],
    },
    {
      topic: "Email marketing",
      subTopics: [
        "write effective content",
        "increase leads through nurturing",
        "drip email campaigns",
        "best email templates for communication"
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default DigitalMarketing

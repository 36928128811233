import React from 'react'
import {Link} from "react-router-dom"
import "./footer.css"
import logo from "../../assets/home/New_logo.png"
import linkedin from "../../assets/footer/linkedin.svg"
// import facebook from "../../assets/footer/facebook.svg"
import twitter from "../../assets/footer/twitter.svg"
import youTube from "../../assets/footer/youTube.svg"
import pinterest from "../../assets/footer/pinterest.svg"
import instagram from "../../assets/footer/instagram.svg"
import location from "../../assets/footer/location.svg"
import phone from "../../assets/footer/phone.svg"
import mail from "../../assets/footer/mail.svg"

function Footer() {
  return (
    <footer className='footer'>
     <div className = 'bg-[#EFEFEF] p-[10%] md:p-[6%_5%] flex flex-wrap md:gap-[40px_10%] gap-[40px_0px] items-baseline'>
      <div className = 'w-[100%] md:w-[25%] flex flex-col md:gap-12 gap-7'>
        <img className = ' w-[120px] md:w-[180px]' src ={logo} alt = 'logo'/>
        <p className = ' text-[10px] md:text-[14px]'>Academor is an e-learning platform offering students a holistic learning experience, helping them become industry ready.</p>
       <div className ="flex gap-2 items-center">
        <p className = 'text-[12px] md:text-[14px]'>Follow Us:</p>
         <div className ="flex gap-2 [&>a]:block [&>a>img]:w-[24px] [&>a>img]:h-[24px] md:[&>a>img]:w-[34px] md:[&>a>img]:h-[34px]">
        
            <a target={"_blank"} href = "https://www.linkedin.com/company/academor/"><img src = {linkedin}  alt ='linkedin'/></a>
            {/* <a target={"_blank"} href = "https://www.facebook.com/profile.php?viewas=100000686899395&id=100084745865313"><img src = {facebook}  alt ='facebook'/></a> */}
            <a target={"_blank"} href="https://twitter.com/academor_22"><img src = {twitter}   alt = 'twitter'/></a>
           
            <a target={"_blank"} href="https://in.pinterest.com/academor/"><img src = {pinterest}   alt = 'pinterest'/></a>
            <a target={"_blank"} href = "https://www.youtube.com/@AcademorOfficial"><img src = {youTube}   alt = 'youTube'/></a>
            <a target={"_blank"} href = "https://instagram.com/academor?igshid=YmMyMTA2M2Y="><img src = {instagram} alt ='instagram'/></a>
            
         </div>
       </div>
      </div>
      <div className = 'footer-col'>
        <p className='footer-heading'>Academor</p>
         <Link to= '/Aboutus'>About Us</Link>
         <Link to= '/Blog'>Blog</Link>
         <Link to= '/contact'>Contact Us</Link>
      </div>

      <div className = 'footer-col'>
        <p className='footer-heading'>Community</p>
         <Link to= '/Termsandconditions'>Terms And Conditions</Link>
         <Link to= '/privacy'>Privacy policy</Link>
         <Link to= '/Career'>Career</Link>
      </div>
       
       <div className='footer-col footer-last [&>a>img]:w-[20px] [&>a>p]:items-center [&>a>img]:h-[20px] [&>a]:flex  [&>a]:gap-3 [&>a]:items-center'>
       <p className='footer-heading'>Find Us At</p>
       <a  href='tel:8310186079'>
        <img src = {phone} alt = 'phone'/>
        <p>8310186079</p>
       </a>
       <a  href='mailto:support@academor.com'>
        <img src = {mail} alt = 'mail'/>
        <p>support@academor.com</p>
       </a>
       <a href="https://maps.app.goo.gl/4Vytq9JECZhmiNWr7" target="_blank">
        <img src={location} alt='mail' />
        <p>Galactic Spaces, 14th A Cross Rd, HSR Layout Sector-6, Bengaluru, Karnataka 560102</p>
       </a>

       </div>
   
     </div>
     {/* <div className='text-center p-4 text-[#ACABAB] text-[12px] font-semibold'> */}
        {/* <p>© Copyright {new Date().getFullYear()} Pixelcurve All rights reserved.</p> */}
    {/* </div> */}
    </footer>
  )
}

export default Footer

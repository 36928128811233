import React from 'react'
// import Carousel from '../carousel/carousel'
// import MentorCard from '../mentorCard/mentorCard'
import mentorsImg from "../../../src/assets/mentorAssets/mentorsImg.png"
import SectionUnderlineHeading from '../sectionUnderLineHeading/sectionUnderlineHeading'
import "./mentorTestimonial.css"

function MentorTestimonial() {
    // const mentorCarouselOptions = {
    //     margin: 60,
    //     nav: true,
    //     dots:false,
    //     responsiveClass: true,
    //     responsive: {
    //       0: {
    //         items: 1,
    //       },
    //       992:{
    //         items:2
    //       }
    //     },
    //   };
  return (
    <div className='flex flex-col md:flex-row px-[10%] md:px-[5%] pb-[10%] pt-[1%] md:pb-[5%]'>
        <section className = 'md:w-[45%] flex flex-col gap-[32px] items-start md:pr-[2%]'>
            <SectionUnderlineHeading customClass='m-carousel-heading' heading='Meet our team of creative experts'/>
            <p className='paragraph  md:pr-[10%]'>Our experts provide supportive, personalised teaching devoting themselves and their knowledge to the time bounded classes. Greatly experienced and defining professionals of core disciplines in the branch of Machine learning.</p>
            <p className='paragraph  md:pr-[10%]'>With the best trainers, you get all the necessary influence on professional foregrounds and character backgrounds which helps you learn and grasp concepts easily. Learnskill provides you a platform with such professionals to assist you through the end of the course.</p>
        </section>
        <div className='md:w-[55%] md:p-0 py-5  w-[100%] m-auto relative'>
          <img src={mentorsImg} alt="mentorsimage" />
        {/* <Carousel options={mentorCarouselOptions} customClass = 'mentorCarousel'>
            <MentorCard/>
            <MentorCard customClass='secondary'/>
            <MentorCard/>
            <MentorCard customClass='secondary'/>
            <MentorCard/>
            <MentorCard customClass='secondary'/>
            
        </Carousel> */}
        </div>
       
    </div>
  )
}

export default MentorTestimonial
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import "./navbar.css";
import logo from "../../assets/home/New_logo.png";
import navBurger from "../../assets/navbar/nav-burger.svg";
import NavDropDown from "../dropdown/dropdown";
import linkedin from "../../assets/footer/linkedin.svg"
import facebook from "../../assets/footer/facebook.svg"
import twitter from "../../assets/footer/twitter.svg"
import instagram from "../../assets/footer/instagram.svg"
import {GrClose} from "react-icons/gr"
import {ImPhone} from "react-icons/im"
import {IoIosMail} from 'react-icons/io'

function Navbar() {
  const navLinkWrapper = useRef();
  const navLinkContainer = useRef();
  const burgerRef =useRef()
  const openNavBar = () => {
   
    if(window.innerWidth>=768) return;
   
    navLinkWrapper.current.style.right='0';
  };
  const closeNavBar = () => {
   
    if(window.innerWidth>=768) return;
  
    navLinkWrapper.current.style.right = "";
  };

  const bodyClick = (e) => {
    if(window.innerWidth>=768) return;
    if (navLinkContainer?.current?.contains(e.target)||burgerRef?.current?.contains(e.target)) return;

    closeNavBar();
  };

  

  // links of course pages
  const innerCourseLinks = [
    { name: "Computer Science", path: "/computer-science" },
    { name: "Electronics And Communication", path: "/electronicsAndCommunication" },
    { name: "Management", path: "/Management" },
    { name: "Biotechnology", path: "/Biotechnology" },
    { name: "Civil And Mechanical", path: "/civilAndMechanical" },
    
  ];

  useEffect(() => {
    window.addEventListener("click", bodyClick);
    return () => {
      window.removeEventListener("click", bodyClick);
    };
  }, []);

  return (
    <div className="p-[15px_8%] bg-[#fff] sm:p-[20px_5%] flex justify-between  items-center">
      <Link to="/">
        <img
          className="w-[120px] sm:w-[150px] tablet:w-[180px]"
          alt="logo"
          src={logo}
        />
      </Link>
      <div ref = {navLinkWrapper} className='nav-link-backdrop-wrapper'>
      <div className="nav-backdrop"></div>
      <div ref = {navLinkContainer} className="nav-link-container">
        <GrClose className='md:hidden self-end' onClick={closeNavBar}/>
      <Link className='md:hidden'  onClick={closeNavBar} to="/">
        <img
          className="w-[120px] sm:w-[150px] tablet:w-[180px]"
          alt="logo"
          src={logo}
        />
      </Link>
            <Link className="nav-link two" onClick = {closeNavBar} to = '/'>Home</Link>
            <Link className="nav-link two" onClick = {closeNavBar} to = '/internship-program'>Internship Program</Link> 
            {/* <Link className="nav-link two" onClick = {closeNavBar} to = '/Hiring'>Hire From US </Link> */}
            <Link className="nav-link two" onClick = {closeNavBar} to = '/Aboutus'>About Us</Link>
          <Link className='nav-link two' onClick = {closeNavBar} to = '/career'>Career</Link>
            <Link className='nav-link two' onClick = {closeNavBar} to = '/contact'>Contact Us</Link>
          <div className ="md:hidden side-nav-bottom">
          <a className="nav-contact-link" href = 'tel:90199 23649'>
              <ImPhone/>
              <p>90199 23649</p>
            </a>
            <a href = "mailto:support@academor.com" className="nav-contact-link"><IoIosMail/>
             <p>support@academor.com</p>
            </a>
          <div className ="flex gap-2 self-center [&>a]:block [&>a>img]:w-[24px] [&>a>img]:h-[24px] md:[&>a>img]:w-[34px] md:[&>a>img]:h-[34px]">
            <a target={"_blank"} href = "https://www.linkedin.com/company/academor/"><img src = {linkedin}  alt ='linkedin'/></a>
            <a href = "#"><img src = {facebook}  alt ='facebook'/></a>
            <a href = "#"><img src = {twitter}   alt = 'twitter'/></a>
            <a  target={"_blank"} href = "https://instagram.com/academor?igshid=YmMyMTA2M2Y="><img src = {instagram} alt ='instagram'/></a>
         </div >
            
          </div>
          
      </div>
      </div>
    
     
      <img
        className="md:hidden w-[30px] h-[30px]"
        ref ={burgerRef}
        onClick={openNavBar}
        src={navBurger}
        alt="burger"
      />
       
   
    </div>
  );
}

export default Navbar;


import React from "react";
import "./faq.css";
import Accordian from "../accordian/accordian";
import SectionUnderlineHeading from "../sectionUnderLineHeading/sectionUnderlineHeading";
import waveAnimation from "../../animations/wave.json";
import Lottie from "react-lottie";

function FAQ() {
  const waveAnimationOptions = {
    loop: true,
    animationData: waveAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const faqData = [
    {
      question: "Are there any educational criteria involved for the courses?",
      answer: `No, there is no any educational criteria or pre-qualification involved
      for admission in any course.`,
    },
    {
      question: "When will I receive the certificate for the course?",
      answer: `Upon completion of the desired course, certificate will be mailed to
      the registered mail id`,
    },
    {
      question: "What are the courses that have a good scope now?",
      answer: `Scope depends on the now functioning professional of the world.
      Depends solely on how you use to change it.`,
    },
  ];

  return (
    <div className="relative overflow-hidden">
      <SectionUnderlineHeading heading="Frequently Asked Questions" />
      <div className="flex w-[80%] md:w-[66%] tablet:w-[55%] m-[7%_auto] md:m-[5%_auto] flex-col gap-[25px] justify-center">
        {faqData &&
          faqData.map((item, i) => {
            return (
              <Accordian
                customClass="faq"
                key={i}
                head={<p>{item.question}</p>}
                body={<p>{item.answer}</p>}
              />
            );
          })}
      </div>
      <div className="absolute -z-10 w-[100%] h-[100%] left-0 top-0">
        <Lottie options={waveAnimationOptions} />
      </div>
    </div>
  );
}

export default FAQ;

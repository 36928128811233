import React from "react";

function SectionTwoPoint({
  heading = "",
  flexDirection = "",
  para = "",
  img = "",
  origin = "",
  position = "",
  rotate = "",
}) {
  return (
    <div
      className={`flex ${flexDirection} justify-end tablet:justify-start gap-4 tablet:gap-0 items-center relative w-[100%] lg:w-[30%] tablet:w-[33%]`}
    >
      <section>
        <h2 className="text-[#000247] text-sm sm:text-lg tablet:text-center font-bold">
          {heading}
        </h2>
        <p className="text-sm  tablet:px-3 tablet:text-center text-[#6F6F6F]">
          {para}
        </p>
      </section>
      <img className="block w-12 h-12 " alt="point-icon " src={img} />
      <span
        className={`bg-[#6F6F6F] absolute top-1/2 hidden  tablet:block w-[30%] opacity-50 h-[1px] ${position} ${origin} ${rotate}`}
      ></span>
    </div>
  );
}

export default SectionTwoPoint;

import React from "react";
import "../computerScience/computerScience.css";
import Section1 from "../../../components/departmentPage/section1/section1";
import Section2 from "../../../components/departmentPage/section2/section2";
import DepartmentCarousel from "../../../components/departmentPage/departmentCarousel/departmentCarousel";
//images
import autoCad from "../../../assets/departmentLanding/civilAndMechanical/autocad.png"
import cardesign from "../../../assets/departmentLanding/civilAndMechanical/carDesign.png"
import hybridvehicles from "../../../assets/departmentLanding/civilAndMechanical/hybridVehicles.png"
import icengine from "../../../assets/departmentLanding/civilAndMechanical/ICEngine.png"
import civilVideo from "../../../assets/departmentLanding/civilAndMechanical/civilVideo.mp4"
import constructionandManagement from "../../../assets/departmentLanding/civilAndMechanical/Construction Planningandmanagement.png"
import { Helmet } from 'react-helmet';

function CivilAndMechanical() {
    //  section 1 data
  const section1Data = {
    heading: (
      <h1 className="multiple-color two">
         <span>Academor:</span> Excellence in courses, led by exceptional instructors.
      </h1>
    ),
    url: civilVideo,
    content: `With our immersive internships in Mechanical and Civil Engineering at Academor, we offer comprehensive lessons, problem-solving, and solutions in civil and mechanical engineering. Interns will work on generating relevant resources and gain specialized knowledge in their branch, ensuring a deep understanding of the subject matter.`,
  };

  //section2 courses data courseImg: 
  const heading = "Civil And Mechanical"
  const courseArr = [
    { courseName: "AutoCAD", rating: "6", link: "/AutoCad",courseImg:autoCad},
    { courseName: "Hybrid Electric Vehicles", rating: "6", link: "/HybridElectricVehicles", courseImg:hybridvehicles},
    { courseName: "Car Design", rating: "6", link: "/CarDesign", courseImg:cardesign},
    { courseName: "IC Engine", rating: "6", link: "/IcEngine", courseImg:icengine},
    { courseName: "Construction Planning and Management", rating: "6", link: "/ConstructionPlanning", courseImg:constructionandManagement},

  ];
     //section 3 related departments
     const relatedDepartments = [
      {
        departmentName: "Computer Science",
        totalCourses : "80",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/computer-science"
      },
      {
        departmentName: "Management",
        totalCourses : "6",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/Management"
      },
      {
        departmentName: "Electronics And Communication",
        totalCourses : "5",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/electronicsAndCommunication"
      },
      {
        departmentName: "Biotechnology",
        totalCourses : "2",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link:"/Biotechnology"
      },
    ]
  return (
    <>
      <Helmet>
        <title>Civil Internship Program | Mechanical Internship Program</title>
        <meta name="description" content="With our immersive internships program in Mechanical and Civil Engineering at Academor, we offer comprehensive lessons."/>
        <meta name="keywords" content="Civil Internships for students, mechanical internship for students, Online internships program for civil students, Online internships program for mechanical students"/>
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 courseArr={courseArr} heading={heading}/>
      <DepartmentCarousel relatedDepartments = {relatedDepartments}/>
    </>
  );
}

export default CivilAndMechanical;

import React from 'react'
import {Link} from "react-router-dom"
import cardImg from "../../../assets/home/sec-4-card-image.svg"

function DepartmentCard({path='/',heading,courseNo,img=cardImg,width}) {
  return (
    <Link className={`${width} hover:shadow-[0px_0px_14px_#00000029] relative h-[100px] flex items-center justify-between bg-[#fff] rounded-[10px] border border-[#D6D6D6]`} to = {path}>
      <span className='absolute bg-[#FFF8F8] z-0 left-[0]  w-[20%] h-[100%] rounded-[50%]  rounded-l-[10px]'></span>
        <img alt = 'course-img' className='w-[24%] h-[100%] z-10' src = {img}/>
        <section className='text-right py-3 pr-10'>
          <h2 className="font-[700] text-sm  tablet:text-[1rem] xl:text-xl">{heading}</h2>
          <h3 className = "text-[#949494] text-[12px] md:text-[14px] tablet:text-[1rem]">{courseNo}</h3>
        </section>
    </Link>
  )
}

export default DepartmentCard
import React from 'react';
import { Link } from 'react-router-dom';
import './Blog.css'; // Import your blog CSS file
import androidImg from "./Images/Image1.jpg";
import androidImg1 from "./Images/Image2.jpg";
import androidImg2 from "./Images/Image31.jpg";


function Blog() {
  return (
    <>
      <h1 className="hero">Blogs</h1>
      <div className="grey">
        <div className="content">
          <div className="blog-container">
            {/* Blog Post 1 */}
            <div className="blog-post">
              <Link to="/BlogPost1"> 
                <img src={androidImg} alt="Blog Post 1" />
                <h2>The Power Of Critical Thinking</h2>
              </Link>
            </div>

            
            <div className="blog-post">
              <Link to="/BlogPost2">
                <img src={androidImg1} alt="Blog Post 2" />
                <h1>Web Development Technologies and Their Impacts on Artificial Intelligence</h1>
              </Link>
            </div>


            <div className="blog-post">
              <Link to="/BlogPost3">
                <img src={androidImg2} alt="Blog Post 2" />
                <h1>The Future of Digital Marketing: Mastering Content, SEO, SEM, and Social Media</h1>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;

import React from 'react';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import nanoImg from "../../../assets/Allcoureses/nanoscience.png"
import Section3 from './../../../components/innerPage/section3/section3';

function NanoTechnologies() {
     //section1Data
  const section1Data = {
    sectImg:nanoImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Nano Technologies</span> with the best instructors
      </h1>
    ),
    description:
     "Our comprehensive course will introduce you to the intriguing world of nanotechnology. Learn about the manipulation of matter at the nanoscale, as well as its medical and electronic applications. Explore this cutting-edge discipline with us. Enroll in an exciting educational voyage today!",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "A discipline in science that deals with engineering with designing conducted at nanoscale, and atomic surrounding.",
    points : [
     "Start your career in nanotechnology by starting with measuring technologies and techniques.",
     "Learn about the materials and procedures used to engineer nano devices and machines.",
     "Nano engineering using bio material to produce eco-friendly inventions in nanoscale.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Introduction to nanotechnology",
      subTopics: [
       "Fundamentals",
       "Nanoparticles and its history",
       "Size and its importance",
      ],
    },
    {
      topic: "Solid state physics basics",
      subTopics: [
        "Electron waves and energy band gaps",
        "Arrangement of atoms in crystals",
        "Conductivity and its classifications",
      ],
    },
    {
      topic: "Advanced tools",
      subTopics: [
        "advanced tools for measurement of nanoscale"
      ],
    },
    {
      topic: "Nanoscale measurement techniques",
      subTopics: [
        "Mapping and identification",
        "Electron microscopy, scanning probe microscopy",
        "Diffraction based techniques",
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default NanoTechnologies

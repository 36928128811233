import React from 'react'
import defaultCardImg from "../../../assets/departmentLanding/dep-card-img.png"
import "./departmentCard.css"
import { Link } from 'react-router-dom';
function DepartmentCard({departmentImage =defaultCardImg, link = '/',departmentName,totalCourses,departmentDes}) {
  return (
    <Link to = {link} className='w-[100%] no-underline relative flex shadow-[0px_3px_8px_#00000029] bg-[#fff] rounded-[10px] p-[10px_10px_0px_0px] dep-carousel-card'>
     <img className = 'block self-end dep-carousel-card-img' src = {departmentImage} alt = 'card-img'/>
      <section className='w-[70%] self-center'>
        <h1 className='font-[700] text-[18px]'>{departmentName}</h1>
        <p className = 'text-[#404040] font-light text-[14px]'>{totalCourses} Courses</p>
        {/* <p className='text-[#686868] font-light text-[12px]'>{departmentDes}</p> */}
      </section>
    </Link>
  )
}

export default DepartmentCard
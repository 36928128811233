import React, { useState } from 'react';
import './career.css';
import grid1 from '../../assets/contactUs/RepeatGrid.svg';
import contactanimation from '../../assets/contactUs/75948.json';
import Lottie from 'react-lottie';
import Thanks from './Thanks';
import { Helmet } from 'react-helmet';

function Career() {
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [showThanksPopup, setShowThanksPopup] = useState(false);

  const contactUsanimation = {
    loop: true,
    animationData: contactanimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const form = event.target;
    const formData = new FormData(form);

    try {
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbw1E-czFb9anH926P6KnkTKwDaGWo7bIJLgfNwROMAgPV_TmRs9gY8xH3ddC66uUYSk/exec',
        {
          method: 'POST',
          body: formData,
        }
      );

      if (response.ok) {
        setSubmissionStatus('success');
        form.reset();
        setShowThanksPopup(true);
      } else {
        setSubmissionStatus('error');
      }
    } catch (error) {
      console.error('Error occurred:', error);
      setSubmissionStatus('error');
    }
  };

  return (
    <>
      <Helmet>
        <title>Sales job | Career opportunities | Freshers jobs | Academor</title>
        <meta name="description" content="Academor is hiring for an Inside Sales position (in-office). Freshers are welcome to apply!" />
        <meta name="keywords" content="Sales job, business development jobs, business development executives, Internships on sales jobs, freshers jobs" />
      </Helmet>
      <div className="car-contactMain">
        <div className="car-contact-details">
          <img src={grid1} alt="GridDots" />
          <h1 className="car-contact-us">Why Academor</h1>
          <ul className="car-bullet-list">
            <li>
              Join our team to explore diverse career opportunities in education, administration, and technology.
            </li>
            <li>
              We offer competitive salaries and comprehensive benefits packages to support your professional growth.
            </li>
            <li>
              Thrive in an innovative environment that values creativity and embraces cutting-edge technology.
            </li>
            <li>
              Achieve work-life balance with our flexible work options and supportive work culture.
            </li>
          </ul>
          <div className="car-contact-sub1">
            {/* Add contact details here */}
          </div>
        </div>
        <div className="car-contactForm">
          <form onSubmit={handleSubmit}>
            <div className="car-contact-name">
              <label className="car-input-label">Full Name</label>
              <input
                className="car-contactUs-input"
                type="text"
                placeholder="Full Name"
                name="Full Name"
                required
              />
            </div>

            <label className="car-input-label">Email Address</label>
            <input
              className="car-contactUs-input"
              type="email"
              placeholder="Email"
              name="Email"
              required
            />

            <label className="car-input-label">Phone Number</label>
            <input
              className="car-contactUs-input"
              type="tel"
              placeholder="Contact Number"
              name="Contact Number"
              required
            />

            {/* Resume upload field */}
            <label className="car-input-label">College Name</label>
            <input
              className="car-contactUs-input"
              type="text"
              placeholder="College Name"
              name="College Name"
              required
            />
            <label className="car-input-label">Interested Domain</label>
            <select
              className="car-contactUs-input"
              name="Interested Domain"
              required
            >
              <option value="" disabled selected>Select One</option>
              <option value="Academic Counsellor">Academic Counsellor/Inside sales</option>
              <option value="Human resources">Human resources</option>
              <option value="Operations">Operations</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Graphic Designer">Graphic Designer</option>
            </select>

            {/* Add other form fields here */}
            <button className="car-btn-primary" type="submit">
              <b>SUBMIT</b>
            </button>
          </form>
          {submissionStatus === 'error' && (
            <p>An error occurred. Please try again later.</p>
          )}
          <img src={grid1} alt="GridDots" />
        </div>
        {showThanksPopup && <Thanks onClick={() => setShowThanksPopup(false)} />}
      </div>
    </>
  );
}

export default Career;

import React from "react";
import "../computerScience/computerScience.css";
import Section1 from "../../../components/departmentPage/section1/section1";
import Section2 from "../../../components/departmentPage/section2/section2";
import DepartmentCarousel from "../../../components/departmentPage/departmentCarousel/departmentCarousel";
import { Helmet } from 'react-helmet';
//images

import digitalmarketing from "../../../assets/departmentLanding/Management/digital marketing.png"
import finance from "../../../assets/departmentLanding/Management/finance.png"
import humanresources from "../../../assets/departmentLanding/Management/human resources.png"
import marketing from "../../../assets/departmentLanding/Management/marketing.png"
import managementVideo from "../../../assets/departmentLanding/Management/managementVideo.mp4"
import stockmarket from "../../../assets/departmentLanding/Management/stock market.png"

function Management() {
  //  section 1 data
  const section1Data = {
    heading: (
      
      <h1 className="multiple-color two">
        <span>Academor:</span> Excellence in courses, led by exceptional instructors.
      </h1>
    ),
    url: managementVideo,
    content: `Welcome to our esteemed Management Internship Program, meticulously crafted to equip individuals with the essential skills and knowledge to flourish in the dynamic business landscape of today. This comprehensive course provides participants with a certified recognition across various management domain.`,
  };

  //section2 courses data
  const heading = "Management"
  const courseArr = [
    {
      courseName: "Digital Marketing",
      rating: "6",
      link: "/DigitalMarketing",
      courseImg: digitalmarketing,
    },
    {
      courseName: "Finance",
      rating: "6",
      link: "/Finance",
      courseImg: finance,
    },
    {
      courseName: "Human Resources",
      rating: "6",
      link: "/Humanresources",
      courseImg: humanresources,
    },
    {
      courseName: "Marketing",
      rating: "6",
      link: "/Marketing",
      courseImg: marketing,
    },
    {
      courseName: "Stock Market",
      rating: "6",
      link: "/StockMarket",
      courseImg: stockmarket,
    },
    {
      courseName: "Business Analytics",
      rating: "6",
      link: "/BusinessAnalytics",
      courseImg: stockmarket,
    },
  ];
   //section 3 related departments
  const relatedDepartments = [
    {
      departmentName: "Computer Science",
      totalCourses : "8",
      // departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      // eiusmod`,
      link: "/computer-science"
    },
    {
      departmentName: "Civil/Mechanical",
      totalCourses : "4",
      // departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      // eiusmod`,
      link: "/civilAndMechanical"
    },
    {
      departmentName: "Electronics And Communication",
      totalCourses : "5",
      // departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      // eiusmod`,
      link: "/electronicsAndCommunication"
    },
    {
      departmentName: "Biotechnology",
      totalCourses : "2",
      // departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      // eiusmod`,
      link:"/Biotechnology"
    },
  ]
  return (
    <>
      <Helmet>
        <title>Online Management internship program | MBA Internships</title>
        <meta name="description" content="Academor online MBA management Internship program for students, meticulously crafted to equip individuals with the essential skills & knowledge"/>
        <meta name="keywords" content="Online Management internship program in India,Online MBA Internships for students, MBA Internships for students"/>
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 courseArr={courseArr} heading={heading}/>
      <DepartmentCarousel relatedDepartments={relatedDepartments}/>
    </>
  );
}

export default Management;

import React from 'react'
import "./internshipDeptCard.css"
import {Link} from "react-router-dom"
import box from "../../assets/internshipLanding/sec-2-card-box.svg"

function InternshipDepartmentCard({departmentName,description,courseCount,path}) {
  return (
    <section className='w-[100%] p-[17px_18px] rounded-[10px]  shadow-[0px_0px_17px_#00000029] md:w-[40%] lg:w-[30%] xl:w-[27%]  int-dept-card'>
         <img alt = 'box' src = {box}/>
         <h1 className='font-bold text-lg pb-2'>{departmentName}</h1>
         <p className='pb-2'>{courseCount} Courses</p>
         <p className = 'text-[12px] p-[0px_50%_15px_0px] text-[#686868]'>{description}</p>
        <Link className = 'text-[14px] no-underline inline-block text-[#fff] bg-[#fd0000] rounded-lg p-[8px_25px]' to = {path}>Explore</Link>
    </section>
  )
}

export default InternshipDepartmentCard 
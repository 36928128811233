import React from 'react';
import { Helmet } from 'react-helmet';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import datascienceImg from '../../../assets/Allcoureses/data science.png';
import Section3 from './../../../components/innerPage/section3/section3';

function DataScience() {
  // section1Data
  const section1Data = {
    sectImg: datascienceImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Data Science</span> with the best instructors
      </h1>
    ),
    description:
      "Our data science course provides students with a foundational understanding of programming, statistics, and machine learning. Through practical assignments, you will evaluate actual data, draw incisive conclusions, and make data-driven decisions. Ideal for professionals, analysts, and data scientists who desire to prosper in this rapidly growing industry. Join us to maximize the value of your data!",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description: "Combine domain expertise, programming skills in multifield disciplines and various applications of data science.",
    points: [
      "Learn to use complex machine learning algorithms to apply in the domain of working in the cyber-era using data as its function.",
      "Learn advanced analytics, apply concepts of AI to organize to be a professional data scientist.",
      "Use modern tools, concepts of machine learning and knowledge of statistics and related mathematical concepts.",
    ]
  };

  // section 3
  const section3Data = [
    {
      topic: "Introduction and python fundamentals:",
      subTopics: [
        "Introduction to data science and python.",
        "Processing and studying data.",
        "Functions in python.",
        "Object-oriented concepts.",
      ],
    },
    {
      topic: "Python MySQL and NumPy:",
      subTopics: [
        "Setups and database.",
        "Operation in database.",
        "Array, data type object(dtype) in NumPy.",
        "Indexing and basic slicing.",
        "Sorting, searching and counting.",
      ],
    },
    {
      topic: "Statistics fundamentals:",
      subTopics: [
        "Scatterpot and probability.",
        "Covariance and correlation.",
        "Bayes theorem.",
        "Measures of spread and location.",
      ],
    },
    {
      topic: "Regression and classification:",
      subTopics: [
        "Features and labels",
        "Theory and how it works",
        "R squared and coefficient of determination theory.",
        "Model evaluation methods.",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Online Data Science Internships | Data Science Projects</title>
        <meta name="description" content="Academor online data science Internship projects, along with online training course certifications, take you on a transformative journey." />
        <meta name="keywords" content="Online Data Science Internships, Data Science online certification courses, Data Science Internship program, Data Science courses, Data Science Projects for Beginners, Data Science online training, Data Science Training Programs, Introduction to Data Science" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default DataScience;

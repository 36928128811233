import React from 'react';
// import "./popUpModal.css"
import logo from "../../assets/home/logo.png"

function Thanks({onClick}) {
  return (
    <>
  <div className='popUpMain'>
      <div className='popUpform'>
        <p className='popupcross' onClick={onClick}>&#10060;</p>
        <img src={logo} alt="logo"/>
        <div className='popupFromDetails'>
            <p> &nbsp; &nbsp;<b>Thank You for contacting us. Our team will reach out to you within 24 hours!</b></p>
          {/* <button type='button' className='btn-primary formbtn'>OK</button> */}
        </div>
      </div>
    </div>
    </>
  )
}

export default Thanks

import React from 'react';
import { Helmet } from 'react-helmet';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import roboticsImg from "../../../assets/Allcoureses/robotics.png";
import Section3 from './../../../components/innerPage/section3/section3';

function Robotics() {
  // section1Data
  const section1Data = {
    sectImg: roboticsImg, 
    heading: (
      <h1 className="multiple-color">
        Learn <span>Robotics</span> with the best instructors
      </h1>
    ),
    description:
      "Learn to design, construct, and program robots utilizing engineering concepts and computer programming languages. Develop expertise in machine learning, software engineering, problem-solving, and critical thinking.",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description: "Contribute to revolutionize the cyber-human world and engineer concepts of robotics.",
    points: [
      "Deal with topics involving concepts of robotic parts like robotic arm and assembly.",
      "Programming human equivalent intelligence and acts to a computer or a robot.",
      "Mechanical assembly, power distribution, Arduino programming and other concepts that build robots.",
    ]
  };

  // section 3
  const section3Data = [
    {
      topic: "Robotic arm and controlling robotic arm",
      subTopics: [
        "Mechanism of various parts (autonomous and controlled)",
      ],
    },
    {
      topic: "Controller board",
      subTopics: [
        "Open source platform",
        "Arduino",
        "Interactive projects",
      ],
    },
    {
      topic: "Power distribution",
      subTopics: [
        "Servo shield",
        "Understanding power inputs",
      ],
    },
    {
      topic: "Testing and calibration",
      subTopics: [
        "Testing the functionality",
        "Calibrating the part designed",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Robotics Training and Certification | Robotics Internships</title>
        <meta name="description" content="Immerse yourself in Academor online Robotics Internships training and certification courses. Develop skills in building and programming robots." />
        <meta name="keywords" content="online robotics Internships programs, Robotics Training program, Online Internships Robotics Certification, Building Robots, Programming Robots, Online robotics Internships in India, Robotics Internships near me, Robotics Internships programs for students" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default Robotics;

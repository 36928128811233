import React from 'react'
import './privacy.css'

const Privacy = () => {
  return (
<div>
        
        <div class="hero privacy">
            <h1>Privacy Policy</h1>
        </div>
    
            <div class="grey">
                <div class="content">
                    {/* <!-- <div class="head">
                    </div> -->
                    <!-- <h2>Privacy Policy</h2> --> */}
    
                    <p>Academor is claimed and worked by Academor Edutech Pvt. Ltd. ("We", "Us", "Academor ", "Organization" or "Our", which articulation will mean and incorporate its associates, replacements, and appoints). This Policy comprises a lawful arrangement between You, as the client of the Website, and Academor , as the proprietor of the Website. This Policy doesn't have any significant bearing on outsider sites that are associated through connections to the Website. We urge you to set aside the effort to pursue this Privacy Policy just as those of some other locales you visit, regardless of whether through a connection on that site or in any case.</p>
    
                    <p>BY USING THE COMPANY WEBSITE, OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD, AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE, AND SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR SOLE DISCRETION, AT ANY TIME.</p>
    
                    <h2>APPLICABILITY-</h2>
    
                    <h2>COLLECTION OF INFORMATION:</h2>
    
                    <h3>Personally Identifiable Information-</h3>
    
                    <p>Academor will utilize the data furnished by You just as per the reasons depicted in the Policy. During Your utilization of Our Website, We might gather and deal with such data from You, including yet not restricted to the beneath referenced:</p>
    
                    <p>Data that You give to Us by filling in structures on the Website. This incorporates contact data, for example, name, email address, street number, telephone number, any monetary data, assuming any, exceptional identifiers, for example, client name, account number, and secret key.</p>
    
                    <p>Data that You give when You compose straightforwardly to Us (counting by email).</p>
    
                    <p>Data that You give to Us over the phone. We might make and track the data You share with us;</p>
    
                    <p>Data that You give to Us by finishing reviews.</p>
    
                    <h3>Non Personal Information-</h3>
    
                    <p>At the point when You utilize the Website, Our servers consequently record specific data that Your internet browser sends at whatever point You visit any site. These server logs might incorporate data, for example, Your web demand, Internet Protocol (IP) address, program type, alluding/leaving pages and URLs, number of snaps, area names, points of arrival, pages seen, and other such data. We utilize this data, which doesn't recognize clients, to investigate patterns, to control the Website, to follow clients' developments around the webpage, and to assemble segment data about the client base overall. We don't connect this naturally gathered information to by and by recognizable data.</p>
    
                    <p>At the point when You utilize the Website, We might utilize clear web signals which are utilized to follow Your online use designs namelessly. No by and by recognizable data from You is gathered utilizing these web guides. Likewise, We may likewise utilize clear web guides in HTML-based messages shipped off You to follow which messages are opened/saw. Such gathered data is utilized to empower more exact detailing and improve the Website for Our clients.</p>
    
                    <h2>COOKIES-</h2>
    
                    <p>"Cookies" are little records that dwell on Your PC's hard drive and for the most part, contain a mysterious exceptional identifier and are open simply by the site that put them there and no different locales. We might gather data about Your overall web use by utilizing a treat document that is put away on the hard drive of Your PC. Treats assist Us with further developing Our Website and convey a superior and customized administration. Treats empower Us:</p>
    
                    <p>To assess Our clients' size and utilization design.</p>
    
                    <p>To store data about Your inclinations, and permit Us to redo Our Website as indicated by Your inclinations.</p>
    
                    <p>To accelerate Your pursuits.</p>
    
                    <p>To remember You when You return to Our Website.</p>
    
                    <p>You might decline to acknowledge Cookies by initiating the setting on Your program which permits You to deny the setting of Cookies. Notwithstanding, assuming You select this setting You might not be able to get to specific pieces of Our Website. Except if You have changed Your program setting with the goal that it will decline Cookies, Our framework might give Cookies when You sign on to the Website. The utilization of Cookies by Our accomplices, subsidiaries, promoters, or specialist co-ops isn't covered by the Policy. Academor might utilize the administrations of specific outsiders, to work and oversee the Website. Such outsider specialist co-ops might gather the data sent by Your program as a component of a site page demand, including Cookies and your IP address and such data will be administered by the security arrangements of the outsider specialist organizations.</p>
    
                    <h2>USE OF THE INFORMATION:</h2>
    
                    <p>We might utilize the data given by You in the accompanying ways, viz:</p>
    
                    <p>Screen, improve and manage our Website.</p>
    
                    <p>Lead examination and investigation.</p>
    
                    <p>Investigate how the Website is utilized, analyze administration or specialized issues, keep up with security.</p>
    
                    <p>Recollect data to assist you with productively getting to the Website.</p>
    
                    <p>To guarantee that substance from Our Website is introduced in the best way dependent on Your inclinations.</p>
    
                    <p>To furnish You with data that You demand from Us.</p>
    
                    <p>To complete Our commitments emerging from any agreements gone into among You and Us.</p>
    
                    <p>To tell You about changes on Our Website.</p>
    
                    <p>To empower Us to follow Our legitimate and administrative commitments.</p>
    
                    <h2>THIRD-PARTY SERVICES-</h2>
    
                    <p>By and large, the outsider suppliers utilized by Us will just gather, use and reveal your data to the degree important to permit them to play out the administrations they give to us. Notwithstanding, certain outsider specialist co-ops, like installment doors and other installment exchange processors, have their own security arrangements in regard to the data we are needed to give to them for your buy-related exchanges. For these suppliers, we suggest that You read their protection strategies so You can comprehend the way where your own data will be dealt with by these suppliers. When you leave our Website or are diverted to an outsider site or application, you are not generally represented by this Privacy Policy.</p>
    
                    <h2>DISCLOSURE OF INFORMATION-</h2>
    
                    <p>Academor doesn't sell, lease, or in any case give your own or business data to outsiders besides as given in this approach. Academor might impart Your data to outsiders with Your earlier assent and on such an occasion, the outsiders' utilization of Your data will be limited by the Policy. We might store data in areas outside the immediate control of Academor (for example, on servers or information bases co-situated with facilitating suppliers). If We sell or purchase any business or resources, we might unveil Your data, with Your earlier assent, to the forthcoming dealer or purchaser of such business or resources. Client, email, and guest data is for the most part one of the moving business resources in these kinds of exchanges. We may likewise move or appoint such data over the span of corporate divestitures, consolidations, or disintegration.</p>
    
                    <p>We might reveal Your data to specialist co-ops engaged with working Our business. This incorporates installment suppliers, outsider servers, email specialist organizations, and experts like bookkeepers and legal advisors. We claim all authority to unveil your own data in the extension and in circumstances as needed by the law and when we have confidence insincerely that such revelation is important to secure our freedoms, to report speculated criminal behavior, consent to official actions, court requests, or choices, or legitimate interaction served on our site.</p>
    
                    <p>We may likewise utilize Your data, and additionally license chosen outsiders including specialist co-ops, on the Website, with Your earlier assent, to utilize Your data or furnish You with such data which might hold any importance with You and We as well as they might reach You about the equivalent. Assuming You wish to be so reached by the outsiders, any data You give to such outsiders might be perused, gathered, and utilized by them. Regardless of the previously mentioned, You might decide to prevent getting such data from outsiders by keeping in touch with Us.</p>
    
                    <h2>YOUR CHOICES ABOUT YOUR INFORMATION-</h2>
    
                    <p>You may, obviously, decline to submit actual recognizable data through the Website, in which case Academor will most likely be unable to offer particular types of assistance to you. What's more, you might refresh or address your record data and email inclinations whenever by signing in to your record. In the event that you would like us to eliminate your actually recognizable data from our information base, kindly send a solicitation.</p>
    
                    <p>We are not answerable for modifying or eliminating your by and by recognizable data obtained by any outsider who has recently been given your data by us as per this strategy or any outsider to whom you have given such data (regardless of whether by sharing your login and secret key or in any case).</p>
    
                    <h3>LINKS TO THIRD PARTY SITES</h3>
    
                    <p>Our Website may, now and again, contain connections to and from the sites of Our accomplice organizations, associates, and other outsiders. The consideration of a connection doesn't infer any underwriting by Us of the outsider site, the site's supplier, or the data on the outsider site. Assuming You follow a connection to any of these sites, if it's not too much trouble, note that these sites might be represented by their own security strategies and We repudiate all liability or risk as for these arrangements or the sites. If it's not too much trouble, check these strategies and the particulars of the sites before You present any data to these sites.</p>
    
                    <h2>INFORMATION STORAGE AND SECURITY-</h2>
    
                    <p>We have executed a wide scope of measures to guarantee the security and privacy of your information. These incorporate physical, electronic and regulatory defences like firewalls, information encryption, SSL, and other state-of-the-art advances. No safety efforts, in any case, are 100% finished. Consequently, we don't guarantee and can't ensure, and in this manner, you ought not to expect that your own data or private interchanges won't be gathered and utilized by others. You should find ways to ensure against unapproved admittance to your secret key, telephone, and PC by, in addition to other things, closing down in the wake of utilizing a common PC, picking a vigorous secret word that no other person knows or can undoubtedly suppose, and keeping your sign in and secret key is hidden. We are not answerable for the unapproved utilization of your data or for any lost, taken, compromised passwords, or for any activity on your Account through unapproved secret phrase movement.</p>
    
                    <p>We can't ensure the security of your information while it is being sent over the Internet and through servers that are out of our control. We put forth a valiant effort to secure your own data however we can't guarantee or warrant the security of any data you send to our Website or Services. In this way, assuming that you make any information transmissions over the Internet, you are doing it in your own danger. When we get the information transmission, we put forth our best attempts to guarantee its security and protection on our frameworks.</p>
    
                    <h2>EXCLUSION-</h2>
    
                    <p>This Policy doesn't make a difference to any data other than such data gathered in any case. This Policy will not matter to any spontaneous data You give Us through this Website or through some other means. This incorporates, yet isn't restricted to, data presented on any open spaces of the Website. All such spontaneous data will be considered to be non-private and Academor will be allowed to utilize, reveal such spontaneous data without restriction</p>
    
                    <h2>SEVERABILITY-</h2>
    
                    <p>We have bent over backward to guarantee that this Policy follows the material laws. The shortcoming or unenforceability of any piece of this Policy will not bias or influence the legitimacy or enforceability of the rest of this Policy.</p>
    
                    <h2>Governing Law And Dispute Resolution-</h2>
    
                    <p>This Policy will be administered by and understood as per the laws of India and the courts at Bengaluru, Karnataka will have a selective ward corresponding to any questions emerging out of or regarding this Policy.</p>
    
                    <h2>Foreign Jurisdiction-</h2>
    
                    <p>Academor makes no portrayal that the substance contained in the Website is fitting or to be utilized anyplace outside of India. The data gave on or through the substance of the Website isn't planned for dispersion to or use by any individual or element in any ward or nation where such dissemination or utilize would be in opposition to law or guideline or which would expose the Company to any enrollment prerequisite inside such purview or country. In like manner, those people who decide to get to the Services from different areas do as such on their own drive and are exclusively answerable for consistency with neighborhood laws, if and to the degree nearby laws are relevant.</p>
    
                    <h2>Changes To The Privacy Policy-</h2>
    
                    <p>We might change this Policy whenever, specifically on a case-by-case basis to follow the principles and guidelines of the different legislative and administrative associations or to consent to pertinent laws and guidelines. The amended adaptation will be compelling at the time we post it on-site or illuminate to the User. Assuming a User utilizes the Services or gets to the site later notification of changes has been shipped off such User or distributed on the Website, such User thus gives his/her/its agreement to the changed terms.</p>
    
                    <h2>Contact-</h2>
    
                    <p>Any required or allowed sees hereunder should be given recorded as a hard copy to the next, by one of the accompanying techniques: (a) by electronic mail; (b) sent by copy, or (c) sent by enrolled mail, postage paid ahead of time; or (d) universally perceived private express dispatch. The Company's location for correspondence is:</p>
    
                    <h2>Applicable Law And Jurisdiction-</h2>
    
                    <p>This Privacy Policy will be administered by, understood, and entered as per the laws of the Republic of India, regardless of the decision of law or struggle of law arrangements thereof.</p>
    
                    <p>All debates as for this Privacy Policy will be brought and heard in the courts arranged at Bengaluru, India.</p>
    
                    
    
                </div>
            </div>
        </div>
  )
}

export default Privacy
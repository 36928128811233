import React from "react";
import "../privacy/privacy.css";
import { Helmet } from 'react-helmet';

function Aboutus() {
  return (
    <>
      <Helmet>
        <title>About Academor -internship Platform for students</title>
        <meta name="description" content="Academor aim to be more than just an ed-tech, by providing a platform for students to connect the dots about their career path."/>
        <meta name="keywords" content="Internship Platform for students, About academor, platform for students"/>
      </Helmet>
      <h1 className="hero"><b>About Us</b></h1>
      <section className="grey">
        <section className="content">
          <p>
            <h1 className="heroTwo"><center><strong>Welcome to Academor!</strong></center></h1><br></br>
            <h5>We aim to be more than just an ed-tech, by providing a platform for students to connect the dots about their career path.</h5>
            
            <h5>We provide over 25 courses so that we can cater to individual needs of each student and help them realize thier goals, in their various fields. You will be guided by more than 45 mentors who we have on board.</h5>
            <h5>Additionally, we have collaborated with over 30 companies, including IBM, Microsoft, and Adobe, so that our students are exposed to real-world experience.</h5>
            At Academor, we outline for you to shine!
          </p>
          <h2 className="heroTwo">Mission</h2>
          <h5>
          Our mission is to transform minds through expert-led internships and foster growth for a brighter tomorrow.
          </h5>
          <h2 className="heroTwo">Vision</h2>
          <h5>
            We want to push the students into the "plus Ultra" phase, taking
            advantage of their knowledge and passion for their field to push
            them even farther. "Tell me and I forget. Teach me and I remember.
            Involve me and I learn". A quote by Benjamin Franklin, And that's
            what Academor does—we expose our students to the industry world so
            they can learn about it.
          </h5>
        </section>
      </section>
    </>
  );
}

export default Aboutus;

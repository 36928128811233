import React from "react";

//css
import "./home.css";

//lottie animation json files
import glowAnimation from "../../animations/glow.json";
import ballAnimation from "../../animations/ballAnimation.json";

//assets
import system from "../../assets/home/system.svg";
import logo from "../../assets/home/New_logo.png";
import sec1Boy from "../../assets/home/sec-1-boy.png"; 
import sec1Card1Front from "../../assets/home/sec-1-card-1-front.svg";
import sec1Card1Back from "../../assets/home/sec-1-card-1-back.svg";
import sec1Card2Front from "../../assets/home/sec-1-card-2-front.svg";
import sec1Card2Back from "../../assets/home/sec-1-card-2-back.svg";

//course-images 
import ML from "../../assets/home/Machine Learning.png"
import DS from "../../assets/home/Data science.png"
import AI from "../../assets/home/Artificial Intelligence.png"
import WD from "../../assets/home/Web Development.png"
import CyberSecurity from "../../assets/home/Cyber Security.png"
import AD from "../../assets/home/app development.png"
import ES from "../../assets/home/embedded systems.png"
import HEV from "../../assets/home/Hybrid electric vehicles.png"
import IOT from "../../assets/home/Internet of things.png"
import robotics from "../../assets/home/Robotics.png"
import Autocad from "../../assets/home/AutoCAD.png"
import ICEngine from "../../assets/home/IC engine.png"
import ConstructionPlanning from "../../assets/home/Construction planning.png"
import DigitalMarketing from "../../assets/home/Digital marketing.png"
import Finance from "../../assets/home/Finance.png"
import StockMarket from "../../assets/home/Stock market.png"
import NanoTechnologies from "../../assets/home/Nanotechnologies.png"

//components
import SectionUnderlineHeading from "../../components/sectionUnderLineHeading/sectionUnderlineHeading";
import Collaboration from "../../components/collaboration/collaboration";
import SectionTwoPoint from "./../../components/home/section2Point/sectionPoint";
import Lottie from "react-lottie";
import Carousel from "../../components/carousel/carousel";
import Sec1Card from "./../../components/home/sec1Card/sec1Card";
import SectionThreePoint from "../../components/home/section3Point/sectionThreePoint";
import DepartmentCard from '../../components/home/departmentCard/departmentCard';
import CourseCard from "../../components/courseCard/courseCard";
import MentorTestimonial from "../../components/mentorTestimonials/mentorTestimonial";
import StudentTestimonialCard from "../../components/studentTestimonialCard/studentTestimonialCard";
import FAQ from "../../components/FAQ Section/faq";
import { Link } from 'react-router-dom';

function Home() {
  //section 2 points information
  const sectionTwoPointArr = [
    {
      heading: "LEARN ANYTHING ONLINE",
      para: "Academor provides you with the basket of necessary skills in the face of courses. Numerous skills you wait to unlock in your system.",
      img: system,
      origin: "origin-left",
      position: "left-[102%]",
      rotate: "rotate-[30deg]",
    },
    {
      heading: "FULLY RESPONSIVE",
      para: "Academor takes the responsibility to guide you in every possible way and beyond to make you reach out of your limits.",
      flexDirection: "tablet:flex-row-reverse",
      img: system,
      origin: "origin-right",
      position: "right-[102%]",
      rotate: "rotate-[-30deg]",
    },
    {
      heading: "DOWNLOAD ALL COURSE'S",
      para: "Academor gives access to the course’s pre-hand to the users for deeper understanding of course and the planned structure.",
      img: system,
      origin: "origin-left",
      position: "left-[102%]",
      rotate: "rotate-[0deg]",
    },
    {
      heading: "MULTIMEDIA UNITS",
      para: "Academor using multimedia stages will help you enable a better understanding of the topic and its applications to better use.",
      img: system,
      flexDirection: "tablet:flex-row-reverse",
      origin: "origin-right",
      position: "right-[102%]",
      rotate: "rotate-[0deg]",
    },
    {
      heading: "COMMUNICATE WITH PEOPLE",
      para: "Academor to make its users industry ready takes all the necessary skills including professional communication and many more.",
      img: system,
      origin: "origin-left",
      position: "left-[102%]",
      rotate: "rotate-[-40deg]",
    },
    {
      heading: "TALK TO OUR EXPERTS",
      para: "To understand our motive better and also your queries, we make stand our experts for your reach.",
      img: system,
      flexDirection: "tablet:flex-row-reverse",
      origin: "origin-right",
      position: "right-[102%]",
      rotate: "rotate-[40deg]",
    },
  ];

  // section 3 points information
  const sectionThreePointArr = [
    {
      number: "01",
      heading: "MAKE YOUR OWN PLACE",
      content:
        "To start with your experience with us, we provide an easy interface for you to access any content with ease for you to feel this place yours also you can create your own arcade here.",
      color: "#BDAFFF",
      customClass: "one",
    },

    {
      number: "02",
      heading: "FIND BEST COURSE",
      content:
        "Find the best course of your choice and scope the world accordingly with the guidance from us.",
      color: "#FFFAAC",
      customClass: "two",
    },

    {
      number: "03",
      heading: "BECOME A MASTER",
      content:
        "Learn skills and concepts that help you become a master of the field you choose.",
      color: "#C7FFAA",
      customClass: "three",
    },

    {
      number: "04",
      heading: "RECEIVE YOUR CERTIFICATE",
      content:
        "Receive certifications upon completing the course of your choice that helps as an achievement for you becoming a master in that course.",
      color: "#FFDCD6",
      customClass: "four",
    },
  ];

  // section 4 Department cards information
  const DepartmentCardArr=[
    {path:'/computer-science',heading:'Computer Science',courseNo:'8 Courses'},
    {path:'/electronicsAndCommunication',heading:'Electronics & Communication',courseNo:'5 Courses'},
    {path:'/civilAndMechanical',heading:'Civil/Mechanical Courses',courseNo:'5 Courses'},
    {path:'/Management',heading:'Management',courseNo:'6 Courses'},
    {path:'/Biotechnology',heading:'BioTechnology',courseNo:'3 Courses'}
  ]

  // course card array
  const courseCardArr = [
    {
    courseName:'Machine Learning',
    departmentName:'Computer Science',
    rating:5,
    courseLink:'/machineLearning',
    courseImg: ML,
    },
    {
      courseName:'VLSIDesign',
      departmentName:'Electronics & Communication',
       rating:5,
       courseLink:'/VLSIDesign',
       courseImg: NanoTechnologies,
    },
    {
      courseName:'Data Science',
      departmentName:'Computer Science',
       rating:5,
       courseLink:'/dataScience',
       courseImg: DS,
    },

    {
      courseName:'Artificial Intelligence',
      departmentName:'Computer Science',
       rating:5,
       courseLink:'/AI',
       courseImg: AI,
    },

    {
      courseName:'Web Development',
      departmentName:'Computer Science',
       rating:5,
       courseLink:'/WebDevelopment',
       courseImg: WD,
    },

    {
      courseName:'Cyber Security',
      departmentName:'Computer Science',
       rating:5,
       courseLink:'/CyberSecurity',
       courseImg: CyberSecurity,
    },

    {
      courseName:'App Development',
      departmentName:'Computer Science',
       rating:5,
       courseLink:'/AppDevelopment',
       courseImg: AD,
    },

    {
      courseName:'Embedded Systems',
      departmentName:'Electronics & Communication',
       rating:5,
       courseLink:'/EmbeddedSystem',
       courseImg: ES,
    },

    {
      courseName:'Hybrid Electric Vehicles',
      departmentName:'Electronics & Communication',
       rating:5,
       courseLink:'/HybridElectricVehicles',
       courseImg: HEV,
    },

    {
      courseName:'Internet Of Things',
      departmentName:'Electronics & Communication',
       rating:5,
       courseLink:'/InternetOfThings',
       courseImg: IOT,
    },

    {
      courseName:'Robotics',
      departmentName:'Electronics & Communication',
       rating:5,
       courseLink:'/Robotics',
       courseImg: robotics,
    },

    {
      courseName:'Autocad',
      departmentName:'Mechanical Engineering',
       rating:5,
       courseLink:'/AutoCad',
       courseImg: Autocad,
    },

    {
      courseName:'IC Engine',
      departmentName:'Mechanical Engineering',
       rating:5,
       courseLink:'/IcEngine',
       courseImg: ICEngine,
    },

    {
      courseName:'Construction Planning',
      departmentName:'Civil',
       rating:5,
       courseLink:'/ConstructionPlanning',
       courseImg: ConstructionPlanning,
    },

    {
      courseName:'Digital Marketing',
      departmentName:'Management & Commerce',
       rating:5,
       courseLink:'/DigitalMarketing',
       courseImg: DigitalMarketing,
    },

    {
      courseName:'Finance',
      departmentName:'Management & Commerce',
       rating:5,
       courseLink:'/Finance',
       courseImg: Finance,
    },

    {
      courseName:'Stock Market',
      departmentName:'Management & Commerce',
       rating:5,
       courseLink:'/StockMarket',
       courseImg: StockMarket,
    },

    {
      courseName:'Nano Technologies',
      departmentName:'Biotechnology',
       rating:5,
       courseLink:'/NanoTechnologies',
       courseImg: NanoTechnologies,
    },  
]
//testimonials
const testimonials = [
   {
    feedback : `I started my two months internship at Academor as a final year student with lot of doubts and a good will to learn a lot about data science. Academor was my stepping stones from me being a beginner to a person who was able to do a project on "detection of Road Lane Lines". With the immense information from my internship program and the help of my mentors I was able to pull a data science project which I never knew I could accomplish in two months`,
    studentName : "-Aditi"
   },
   {
    feedback : `I liked this course very much. The way it is organised to collect different informative opinions about the same topic, make it very valuable to the ones who are interested in Embedded system.`,
    studentName : "-Sahil"
   },
   {
    feedback : `Towards the end of my internship, I truly began to feel that I could make a difference in today's fast forward era where I really thought I was left back. I feel as if my research and coding skills have massively improved since starting the internship and I feel more confident that my work is good enough for a professional setting. I now have a better idea of what it is like working in an AIML based setting and also how much hard work goes on behind the scenes to make a project happen.`,
    studentName : "-Abhishek"
   },
   {
    feedback : `I was sceptical about joining Academor but after being here for 2 months and doing both my projects I can share about Academor to everyone who I know. they offer really good course materials and the projects are also in a good standard it's not the usual get project and get code on GitHub, they make sure and help you in doing the project.`,
    studentName : "-Satya"
   },
   {
    feedback : `I found the AutoCAD course very helpful. The materials are simple to understand and the mentors are also good and knowledgeable.`,
    studentName : "-Pradeep"
   },
   {
    feedback : `I did my intern in Nano Technologies, not many offer good interns for this course but I’m really glad I came to Academor, not just they offered me this intern but it was very informative and this was not a name’s sake course over here.`,
    studentName : "Jhanvi"
   }
]


 

  const glowAnimationOptions = {
    loop: true,
    animationData: glowAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const ballAnimationOptions = {
    loop: true,
    animationData: ballAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // single slide owl carousal options
  const singleCardCarouselOptions = {
    margin: 20,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };

  // course  owl carousal options
  const courseCarousalOptions = {
    margin: 50,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      640:{
         items:2,
      },
      992:{
        items:3
      }
    },
  };

  const studentCarousalOptions = {
    margin: 50,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      640:{
         items:2,
      },
      992:{
        items:3
      }
    },
  };


  const SectionTwoPointWrapper = ({ arr, width }) => {
    return (
      <div className={`flex justify-between ${width}`}>
        {arr?.length > 0 &&
          arr.map((item, index) => {
            return <SectionTwoPoint key={index} {...item} />;
          })}
      </div>
    );
  };

  return (
    <>
      {/* SECTION 1 */}
      <main className="flex flex-col md:flex-row pb-7 pt-16 items-center ">
        <section className="flex flex-col px-[10%] pb-[5%] gap-8 md:w-[45%] md:pl-[6%]">
          <h2 className="text-[#FF7373] text-sm flex items-center gap-2 ">
            <span className="w-[10px] bg-[#FF7373] h-[1.2px]"></span>Dr.
            Jennifer Fleming
          </h2>
          <blockquote className="multiple-color">
            Teaching in the <span>Internet age</span> means we must teach
            tomorrow’s skills today.
          </blockquote>
          <p className="text-[#474343] font-[600] text-[14px] md:text-[16px]">
          The cyber-era has got countless applications in the real time world that require master skills of professionals to meet the population's demand. Academor keeps this as its meaning and provides a platform for such enthusiasts and with well defined planning structure to enable its users enjoy the whole path to learning.
          </p>
          <a className="btn-primary" href="/internship-program">
            Explore Courses
          </a>
        </section>
        <div className="md:w-[55%] w-[80%] relative">
          <Sec1Card
            className="left-0"
            img1={sec1Card1Front}
            img2={sec1Card1Back}
          />
          <img
            className="block w-9/12 m-auto md:mr-auto md:ml-0 md:relative md:left-[-7%]"
            src={sec1Boy}
            alt="Man"
          />
          <Sec1Card
            className="right-0 md:right-[8%] bottom-0"
            img1={sec1Card2Front}
            img2={sec1Card2Back}
          />
        </div>
      </main>

      {/* SECTION 2 */}
      <section className="relative py-8 tablet:pb-16 tablet:bg-transparent">
        <SectionUnderlineHeading
          heading={"What You'll Learn With Academor ?"}
        />
        <p className="text-sm text-[#6F6F6F] sm:text-center w-10/12 xl:w-5/12 lg:w-6/12  sm:w-8/12 m-auto tablet:py-3 pt-4 pb-8 font-normal">
        Academor with a mission to teach skills that you demand is set up structurally as displayed.
        </p>

        <div className="absolute bg-[#faebd7] -z-10 opacity-50 m-auto w-[100%] h-[100%] top-0">
          <Lottie options={ballAnimationOptions} />
        </div>
        {/* FOR width>992px*/}
        {
          <div className="tablet:flex hidden flex-col lg:gap-24 md:gap-12 items-center relative md:mt-12">
            <img
              alt="logo"
              src={logo}
              className="absolute   m-auto w-[10%] top-1/2 -translate-y-1/2"
            />
            <div className="absolute -z-10 opacity-50 m-auto w-[28%] top-1/2 -translate-y-1/2">
              <Lottie options={glowAnimationOptions} />
            </div>
            <SectionTwoPointWrapper
              arr={sectionTwoPointArr.slice(0, 2)}
              width={"md:w-[75%]"}
            />
            <SectionTwoPointWrapper
              arr={sectionTwoPointArr.slice(2, 4)}
              width={"md:w-[85%]"}
            />
            <SectionTwoPointWrapper
              arr={sectionTwoPointArr.slice(4)}
              width={"md:w-[75%]"}
            />
          </div>
        }

        {/* FOR  width<992px*/}
        {
          <div className="tablet:hidden">
            <div className="mx-auto relative  w-[80%] sm:w-8/12">
              <Carousel
                customClass="home-sec-2-carousel"
                options={singleCardCarouselOptions}
              >
                <div className="flex flex-col gap-9 pb-5">
                  {sectionTwoPointArr?.length > 0 &&
                    sectionTwoPointArr.slice(0, 3).map((item, index) => {
                      return (
                        <SectionTwoPoint
                          key={index}
                          {...item}
                          flexDirection="flex-row-reverse"
                        />
                      );
                    })}
                </div>
                <div className="flex flex-col gap-9">
                  {sectionTwoPointArr?.length > 0 &&
                    sectionTwoPointArr.slice(3).map((item, index) => {
                      return (
                        <SectionTwoPoint
                          key={index}
                          {...item}
                          flexDirection="flex-row-reverse"
                        />
                      );
                    })}
                </div>
              </Carousel>
            </div>
          </div>
        }
      </section>

      {/* SECTION 3 */}
      <section className="py-8">
        <SectionUnderlineHeading heading={"Academor simple Learning Steps"} />
        <div className="flex md:py-[5%] py-5 flex-col gap-[30px]">
          {sectionThreePointArr?.length > 0 &&
            sectionThreePointArr.map((item, i) => {
              return <SectionThreePoint {...item} />;
            })}
        </div>
      </section>

      {/* Section 4 */}

      <section>
        <SectionUnderlineHeading heading="Find out our Popular Department" />
        <div className="w-[90%] px-[5%] pt-[1%] pb-[5%]  mx-auto my-10 rounded-[30px] p-4  bg-gradient-to-r from-[#FFF2F2] to-[#FFFFFF]">
     
     {/* width>=768 */}
      <div className="md:flex hidden gap-y-12 justify-between flex-wrap">
      {DepartmentCardArr?.length>0 &&DepartmentCardArr.map((item,i)=>{
        return <DepartmentCard {...item} width='lg:w-[30%] md:w-[45%]'/>
       })
      }
      </div>

      {/* width<768 */}
       <Carousel customClass="sec-4-home-carousel" options={singleCardCarouselOptions}>
        <div className='flex flex-col py-3 gap-y-12'>
        {DepartmentCardArr?.length>0 &&DepartmentCardArr.slice(0,3).map((item,i)=>{
        return <DepartmentCard {...item} width='lg:w-[30%] md:w-[45%]'/>
       })
      }
        </div>
        <div className='flex flex-col py-3 gap-y-12'>
        {DepartmentCardArr?.length>0 &&DepartmentCardArr.slice(3).map((item,i)=>{
        return <DepartmentCard {...item} width='lg:w-[30%] md:w-[45%]'/>
       })
      }
        </div>
      
       </Carousel>
        
        </div>
      </section>
     {/* collaborationcard */}
     <Collaboration />
      {/* Section 5 */}
      <section id = 'course-section' className="pb-[10%] pt-[1%] md:pb-[5%]  ">
        <SectionUnderlineHeading heading='Become a Pro with Our Latest Courses'/>
        <Carousel customClass="course-carousel" options={courseCarousalOptions}>
          {courseCardArr?.length>0 && courseCardArr.map((item,i)=>{
            return   <CourseCard key = {i} {...item} customClass=''/>
          })}
          
        </Carousel>
        <div className ='m-auto pt-10 text-center'>
        <Link to = "/internship-program" className="btn-primary">Explore Courses</Link>
        </div>
       
      </section>
      {/* SECTION 6 */}
      {/* <MentorTestimonial /> */}

      {/* SECTION 7 */}
      <section className="md:my-[5%] mb-[10%] p-[25px] text-center mx-auto bg-gradient-to-b w-[90%] rounded-[30px] from-[#FFF2F2] to-[#FFFFFF]">
        <h1 className  ='font-[800] pt-3 md:text-3xl text-xl'>Read What the students have to say about us</h1>
        {/* <p className = 'paragraph pt-4 md:px-[15%]'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim</p> */}
         <Carousel customClass="student-carousel" options = {studentCarousalOptions}>
          {testimonials?.length>0 && testimonials.map((item,i)=>{
            return <StudentTestimonialCard key = {i} {...item}/>
          })}
         </Carousel>
      </section>

      {/* FAQ */}
      <FAQ/>
      
    </>
  );
}

export default Home;

import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import geneticImg from "../../../assets/Allcoureses/genetic engineering.png"
import Section3 from './../../../components/innerPage/section3/section3';

function BioInformatics() {
      //section1Data
  const section1Data = {
    sectImg:geneticImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>BioInformatics</span> with the best instructors
      </h1>
    ),
    description:
     "Our exhaustive course explores the cutting edge of Bioinformatics. Acquire knowledge of genomics, data analysis, and computational tools. Enhance your scientific endeavors with us.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "You will learn more about genetic testing and research in this course. A significant and developing area of the applied sciences is genetic engineering. Know the fundamentals and foundations of the subject.",
    points : [
       "Gene Cloning",
       "Transgenic Technologies",
       "Genomics",
       "Forensics"
    ]
  };
     //section 3
     const section3Data = [
      {
        topic: "Introduction to Bio-Informatics",
        subTopics: [
           "Computational biology",
           "Classification of different database and tools",
           "File format of database",
           "Introduction to Alignment",
        ],
      },
      {
        topic: "Different Search Tools",
        subTopics: [
          ".Retrieving FASTA sequence of any Specific Organisms",
          "Gap Penalties &amp; substitution score and matrices"
        ],
      },
      {
        topic: "Bio-Informatics Tools",
        subTopics: [
         "Introduction to Blast tool",
         "Introduction to Pairwise Alignment",
         "Introduction to molecular phylogenetic",
         "Different tools for building phylogenetic tree"
        ],
      },
    ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default BioInformatics

import React,{useState,useEffect} from 'react'
import Pagination from '../../pagination/pagination';
import SectionUnderlineHeading from '../../sectionUnderLineHeading/sectionUnderlineHeading'
import CourseCard from '../courseCard/courseCard';
import "./section2.css"
import Lottie from "react-lottie";
import stars from "../../../animations/stars.json"


function Section2({courseArr,heading}) {
  const starAnimationOptions = {
    loop: true,
    animationData: stars,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const [data,setData] = useState([])
  
  const [pageOptions, setPageOptions] = useState({
    currentPage: 1,
    pageSize: 0,
    totalCount: 1,
  });

  useEffect(()=>{
    let {currentPage,pageSize}=  pageOptions
    let start = (currentPage-1)*pageSize;
    let end = currentPage*pageSize
    let newData = courseArr.slice(start,end)
      setData(newData)         
  },[pageOptions])

  useEffect(() => {
    if (window.innerWidth>=1280) {
      setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 6,totalCount:courseArr.length }); 
      return
    }
    if (window.innerWidth>=768) {
      setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 4,totalCount:courseArr.length }); 
      return
    }

    setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 3,totalCount:courseArr.length });
  }, [window.innerWidth]);

  return (<div className='relative overflow-hidden'>
     <div className = 'mob:p-[3%_0%_3%] p-[6%_0%_3%] relative z-10'>
    <SectionUnderlineHeading heading = {heading}/>
    <div className='flex flex-wrap p-[7%_5%_0%] mob:p-[5%_5%_0%] gap-[50px_0px] md:gap-[60px_5%] xl:gap-[60px_3.5%] relative z-[10] overflow-hidden'>
    {data?.length > 0 &&
            data.map((item, i) => {
                return <CourseCard key = {i} {...item}/>
              })}
    </div>
       <Pagination
            totalCount={pageOptions.totalCount}
            pageSize={pageOptions.pageSize}
            currentPage={pageOptions.currentPage}
            onPageChange={(num,size) => {setPageOptions({...pageOptions,currentPage:parseInt(num),pageSize:parseInt(size)})}}
          />
   
   
    </div>
    <div className="absolute -z-10 left-0 top-0 w-[100%] h-[100%]">
      <Lottie options = {starAnimationOptions}/>
    </div>
  </div>
   
  )
}

export default Section2
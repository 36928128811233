import React from 'react'
import "./comingSoon.css"
import logo from "../../assets/home/logo.png"
import Lottie from 'react-lottie'
import ballAnimation from "../../animations/ballAnimation.json"
import {IoIosMail} from 'react-icons/io'
import facebook from "../../assets/comingSoon/facebook.svg"
import linkedin from "../../assets/comingSoon/linkedin.svg"
import twitter from "../../assets/comingSoon/twitter.svg"
import instagram from "../../assets/comingSoon/instagram.svg"

function ComingSoon() {

  const ballAnimationOptions = {
    loop: true,
    animationData: ballAnimation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  return (
    <div className='relative overflow-hidden pb-[5%]'>
        <div className='relative z-10'>
        <img className='m-auto pt-[5%] block w-[120px] sm:w-[150px] md:w-[180px]' src = {logo} alt = 'logo'/>
        <section className='flex flex-col pt-[15%] gap-[20px_0px] justify-center text-center'>
          <h1 className='text-xl md:text-2xl lg:text-3xl xl:text-4xl font-bold px-[10%]'>We'll be launching our website very soon.</h1>
           <p className='text-[#2A2A2A] p-[0_10%_20px] md:p-[0_15%_20px] lg:p-[0_35%_20px]'>Get notified when we come online!</p>
               <form className='flex justify-center'>
                  <div className='bg-[#fff] flex rounded-[10px_0px_0px_10px] w-[50%]  max-w-[350px] items-center gap-3 p-[10px_15px] sm:p-[20px]'>
                    <IoIosMail/>
                    <input className='outline-none' placeholder='Enter Email Address' required/>
                  </div>
                  <button type ='submit' className='bg-[#FD0000] rounded-[0px_10px_10px_0px] p-[10px_15px] sm:p-[20px] text-[#fff]'>Notify Me</button>
               </form>
        </section>
           <div className='flex justify-center pt-[10%] [&>a]:no-underline [&>a>img]:w-[30px] [&>a>img]:h-[30px] md:[&>a>img]:w-[40px] md:[&>a>img]:h-[40px] items-center gap-3'>
            <a href = '#'>
              <img alt = 'social-media-icon' src = {facebook}/>
            </a>
            <a href = '#'>
              <img alt = 'social-media-icon' src = {twitter}/>
            </a>
            <a href = '#'>
              <img alt = 'social-media-icon' src = {linkedin}/>
            </a>
            <a href = '#'>
              <img alt = 'social-media-icon' src = {instagram}/>
            </a>
           </div>
        </div>
      <div className='absolute z-0 bg-gradient-to-t from-[#FFE8E8] to-[#FFFFFF] top-0 left-0 w-[100%] h-[100%]'>
           
        <Lottie options={ballAnimationOptions}/>
      </div>
    </div>
  )
}

export default ComingSoon
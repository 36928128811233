import React from "react";
import "../computerScience/computerScience.css";
import Section1 from "../../../components/departmentPage/section1/section1";
import Section2 from "../../../components/departmentPage/section2/section2";
import DepartmentCarousel from "../../../components/departmentPage/departmentCarousel/departmentCarousel";
//images
import iot from "../../../assets/departmentLanding/electronicsAndCommunication/iot.png";
import robotics from "../../../assets/departmentLanding/electronicsAndCommunication/robotics.png"
import embeddedsystems from "../../../assets/departmentLanding/electronicsAndCommunication/embeddedSystems.png"
import hybridvehicles from "../../../assets/departmentLanding/electronicsAndCommunication/hybridVehicles.png"
import electronicvideo from "../../../assets/departmentLanding//electronicsAndCommunication/electronics.mp4"
import { Helmet } from 'react-helmet';

function ElectronicsAndCommunication() {
  //  section 1 data
  const section1Data = {
    heading: (
      <h1 className="multiple-color two">
         <span>Academor:</span> Excellence in courses, led by exceptional instructors.
        instructors
      </h1>
    ),
    url: electronicvideo,
    content: `Empower yourself with our Electronics and Communication Courses, meticulously crafted to prepare you for a thriving career in the dynamic electronics and core sectors. Our expert team has curated the curriculum to align with the latest industry requirements. During your  internship with Academor, you'll gain practical insights into skills that are sought after by top core companies.`,
  };

  //section2 courses data
  const heading = "Electronics And Communication"
  const courseArr = [
    { courseName: "Internet Of Things", rating: "6", link: "/InternetOfThings", courseImg:iot},
    { courseName: "Robotics", rating: "6", link: "/Robotics", courseImg:robotics},
    { courseName: "Embedded System", rating: "6", link: "/EmbeddedSystem", courseImg:embeddedsystems},
    { courseName: "Hybrid Electric Vehicles", rating: "6", link: "/HybridElectricVehicles", courseImg:hybridvehicles },
    { courseName: "VLSI Design", rating: "6", link: "/VLSIDesign", courseImg:hybridvehicles },

  ];
    //section 3 related departments
    const relatedDepartments = [
      {
        departmentName: "Computer Science",
        totalCourses : "8",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/computer-science"
      },
      {
        departmentName: "Civil/Mechanical",
        totalCourses : "4",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/civilAndMechanical"
      },
      {
        departmentName: "Management",
        totalCourses : "6",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link: "/Management"
      },
      {
        departmentName: "Biotechnology",
        totalCourses : "2",
        departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
        eiusmod`,
        link:"/Biotechnology"
      },
    ]
  return (
    <>
      <Helmet>
        <title>Online ECE internships | Internship for ECE students</title>
        <meta name="description" content="Online internships for ece electronics and communication courses, meticulously crafted to prepare you for a thriving career in the dynamic electronics"/>
        <meta name="keywords" content="Internship for ECE students in Bangalore, Internship for ECE students in India, Internship for ECE students near me"/>
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 courseArr={courseArr} heading={heading}/>
      <DepartmentCarousel relatedDepartments={relatedDepartments}/>
    </>
  );
}

export default ElectronicsAndCommunication;

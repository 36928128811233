import React from "react";
import { Helmet } from "react-helmet";
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from "./../../../components/FAQ Section/faq";
import cyberImg from "../../../assets/Allcoureses/Cyber security.png";
import Section3 from "./../../../components/innerPage/section3/section3";

function CyberSecurity() {
  // section1Data
  const section1Data = {
    sectImg: cyberImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Cyber Security</span> with the best instructors
      </h1>
    ),
    description:
      "Comprehensive course in cybersecurity encompassing threat analysis, encryption, and network defense. Practical training in the protection of digital assets. Expert educators. Curriculum that is innovative.",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description:
      "Protect computers and other network connected systems from digital attacks and risks.",
    points: [
      "Defend computers, servers and network connected devices by learning concepts of access, authorization and sharing of information.",
      "Tools and technologies to detect breach or change in data.",
      "Inter-disciplinary concepts, applications and understanding cyber networks.",
    ],
  };

  // section 3
  const section3Data = [
    {
      topic: "Networking concepts and overview",
      subTopics: [
        "topic sound",
        "basic of cyber security",
        "understanding of various networks",
      ],
    },
    {
      topic: "Information security concepts",
      subTopics: [
        "tapping into source",
        "understanding nature of various attack",
        "security measures",
      ],
    },
    {
      topic: "Security management practices",
      subTopics: [
        "techniques of tackling hacking",
        "types of hackers",
        "methods in cybersecurity",
      ],
    },
    {
      topic: "Setup lab environment",
      subTopics: [
        "tools and techniques",
        "learning about data",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Cybersecurity Internships Program | Ethical Hacking Internships</title>
        <meta name="description" content="The cybersecurity Ethical hacking methods Internships program with online training courses curriculum at Academor takes students on a thorough exploration" />
        <meta name="keywords" content="Online Cybersecurity certification, online cybersecurity Internships program, Ethical Hacking Internships for students, Cybersecurity career programs for students, Cybersecurity Courses near me, Ethical Hacking Training Programs" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default CyberSecurity;

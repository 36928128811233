import React from "react";
import "./sectionThreePoint.css";

function SectionThreePoint({
  number,
  heading,
  content,
  color = "",
  customClass = "",
}) {
  return (
    <>
      {/* width>768px */}
      <div className={"md:flex hidden w-[82.5%] m-auto items-center  "}>
        <div
          style={{ borderColor: color }}
          className="md:w-[75px] md:h-[75px] lg:w-[90px] lg:h-[90px] translate-x-[40%]   flex items-center justify-center point-wrapper"
        >
          <h2
            style={{ background: color }}
            className={`rounded-[50%] md:text-[1.5rem] lg:text-[2rem] flex items-center justify-center   font-[700] w-[80%] h-[80%] p-5 `}
          >
            {number}
          </h2>
        </div>

        <div
          style={{ borderColor: color }}
          className={`flex items-center curve-border ${customClass} w-[45%] md:h-[75px] lg:h-[90px]`}
        >
          <p className={`md:text-[1rem] lg:text-[1.3rem]  font-[700] pl-[15%]`}>
            {heading}
          </p>
        </div>
        <span
          style={{ background: color }}
          className={`w-[10%] h-[1px] `}
        ></span>
        <p className="paragraph pl-[20px] w-[40%]">{content}</p>
      </div>

      {/* width<768px */}
      <div className="flex md:hidden w-[80%] gap-[10px] m-auto">
        <h2
          style={{ background: color }}
          className={`rounded-[50%] text-[1rem] flex items-center justify-center   font-[700] w-[60px] h-[60px] p-5 `}
        >
          {number}
        </h2>
        <section>
          <h2 className = "text-[1rem] font-[700]">{heading}</h2>
          <p className="paragraph">{content}</p>
        </section>
      </div>
    </>
  );
}

export default SectionThreePoint;

import React from "react";
import "./computerScience.css";
import Section1 from "../../../components/departmentPage/section1/section1";
import Section2 from "../../../components/departmentPage/section2/section2";
import DepartmentCarousel from "../../../components/departmentPage/departmentCarousel/departmentCarousel";
//course images
import CyberSecurity from "../../../assets/departmentLanding/computerScience/cyberSecurity.png";
import cloudComputingimg from "../../../assets/departmentLanding/computerScience/cloudComputing.png";
import ai from "../../../assets/departmentLanding/computerScience/ai.png";
import arvr from "../../../assets/departmentLanding/computerScience/ARVR.png";
import dataScience from "../../../assets/departmentLanding/computerScience/datascience.png";
import machineLearning from "../../../assets/departmentLanding/computerScience/machineLearning.png";
import webDevelopment from "../../../assets/departmentLanding/computerScience/webDevelopment.png";
import androidDevelopment from "../../../assets/departmentLanding/computerScience/androidDevelopment.png";
import csVideo from "../../../assets/departmentLanding/computerScience/computerScienceVideo.mp4"
import { Helmet } from 'react-helmet';
function ComputerScience() {
  //  section 1 data
  const section1Data = {
    heading: (
      <h1 className="multiple-color two">
        <span>Academor:</span> Excellence in courses, led by exceptional instructors.
      </h1>
    ),
    url: csVideo,
    content: `Introducing our exclusive Computer Science Internship Program, a gateway to excel in the dynamic tech landscape. Elevate your skills with hands-on internships, where you'll craft software, explore hardware, and master programming languages, data structures, and AI principles.`,
  };

  //section2 courses data courseImg: 
    const heading = "Computer Science"
  const courseArr = [
    
    { courseName: "Web Development", rating: "6", link: "/WebDevelopment",courseImg:webDevelopment},
    { courseName: "Artificial Intelligence", rating: "6", link: "/AI", courseImg: ai},
    { courseName: "Machine Learning", rating: "6", link: "/machineLearning", courseImg: machineLearning},
    { courseName: "Cyber Security", rating: "6", link: "/CyberSecurity", courseImg:CyberSecurity},
    { courseName: "Cloud Computing", rating: "6", link: "/cloud-computing", courseImg: cloudComputingimg},
    { courseName: "Android App Development", rating: "6", link: "/AppDevelopment", courseImg:androidDevelopment},
    { courseName: "AR/VR", rating: "6", link: "/Arvr", courseImg: arvr},
    { courseName: "Data Science", rating: "6", link: "/dataScience", courseImg: dataScience},
  ];
  //section 3 related departments

  const relatedDepartments = [
    {
      departmentName: "Electronics & Communication",
      totalCourses : "5",
      departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod`,
      link: "/electronicsAndCommunication"
    },
    {
      departmentName: "Civil/Mechanical",
      totalCourses : "4",
      departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod`,
      link: "/civilAndMechanical"
    },
    {
      departmentName: "Management",
      totalCourses : "6",
      departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod`,
      link: "/Management"
    },
    {
      departmentName: "Biotechnology",
      totalCourses : "2",
      departmentDes : `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
      eiusmod`,
      link:"/Biotechnology"
    },
  ]

  return (
    <>
      <Helmet>
        <title>Online internships for IT & CSE students | Academor</title>
        <meta name="description" content="IT Computer Science Internship Program, a gateway to excel in the dynamic tech landscape. Elevate your skills with hands-on expereince"/>
        <meta name="keywords" content="Computer Science Internship Program, internships for IT students, CSE online internships, Computer Science projects"/>
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 courseArr={courseArr} heading={heading}/>
      <DepartmentCarousel relatedDepartments = {relatedDepartments}/>
    </>
  );
}

export default ComputerScience;

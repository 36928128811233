import React from 'react'
import SectionUnderlineHeading from './../../sectionUnderLineHeading/sectionUnderlineHeading';
import Carousel from './../../carousel/carousel';
import DepartmentCard from '../departmentCard/departmentCard';
import "./departmentCarousel.css"

function DepartmentCarousel({relatedDepartments}) {
 
    const options = {
    margin: 50,
    nav: true,
    dots:false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
      640:{
         items:2,
      },
      992:{
        items:3
      }
    },
    }
  return (
   
    <div className = 'p-[7%_5%] md:p-[5%] bg-gradient-to-b from-[#FFE1E1] to-[#FFFFFF]'>
           <SectionUnderlineHeading heading = 'Related Departments'/>
           <Carousel options = {options} customClass='dept-carousel'>
              {
                  relatedDepartments?.length && relatedDepartments.map((item,index)=>{
                    return  <DepartmentCard key={index}
                    {...item}/>
                  })
              }
           </Carousel>
    </div>
  )
}

export default DepartmentCarousel
import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import autoImg from "../../../assets/Allcoureses/autocad.png"
import Section3 from './../../../components/innerPage/section3/section3';

function AutoCad() {
      //section1Data
  const section1Data = {
    sectImg:autoImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>AutoCAD</span> with the best instructors
      </h1>
    ),
    description:
     "Take a course in order to learn AutoCAD. Discover how to design in 2D or 3D, prototype, and annotate. Industry knowledge and beneficial initiatives. This is useful for both novices and those seeking to improve their abilities.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Work with best designing software and design complex parts to build huge or nano scaled mechanical bodies,",
    points : [
      "Learn to use and work with various designing tools and functions.",
      "Build 3d models and the machine itself using commands and specific designing tools.",
      "Work with arrays and construct the best deal of machines.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Theory session",
      subTopics: [
        "Introduction to CAD",
        "Basics of CAD, CAM",
      ],
    },
    {
      topic: "Practical session",
      subTopics: [
        "Design 2d and 3d models",
        "Practical problems",
      ],
    }
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default AutoCad

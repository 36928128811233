import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import iotImg from "../../../assets/Allcoureses/internet of things.png"
import Section3 from './../../../components/innerPage/section3/section3';

function VLSIDesign() {
     //section1Data
  const section1Data = {
    sectImg:iotImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>VLSI Design</span> with the best instructors
      </h1>
    ),
    description:
     "Comprehensive course on VLSI encompassing design processes, CMOS technology, layout, verification, and FPGA implementation. Learn more complex design principles for integrated circuits. Appropriate for aspiring engineers and circuit designers.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Connect various systems to create a network of VLSI-based components that cater to user's specific requirements.",
    points : [
      "Learn the basics and fundamentals of VLSI electronics and explore numerous protocols.",
      "Dive into detailed concepts of VLSI prototyping and network-connected architectures.",
      "Receive expert training in PCB design, manufacturing, and communication networks from experienced tutors.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Introduction of VLSI",
      subTopics: [
       "Introduction to IC Technology",
       "MOS,POS,NMOS,CMOS and BICMOS Technologies fabrication process",
      ],
    },
    {
      topic: "VLSI Circuit Design process ",
      subTopics: [
        "VLSI Design flow",
        "MOS layers",
        "Stick Diagram",
        "Design Rules & layout",
        "Transistor layout diagrams"
      ],
    },
    {
      topic: "Subsystem design",
      subTopics: [
        "Shifters",
        "Adders:Carry skip",
        "Carry select",
        "Square root carry select",
        "PCB Design"
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default VLSIDesign

import React from 'react'
import "./pricingPlan.css"
import greenTick from "../../assets/icons/greenTick.svg"
// import redTick from "../../assets/icons/redTick.svg"


function PricingPlan({type='',pointsArr,price,onClick}) {
 
  return (
    <>
    <div data-type={type} className={`bg-[#fff] w-[99%] m-auto rounded-[10px] text-center shadow-[0px_3px_18px_#00000029] pricingPlan`}>
       <p className = 'pricingtype text-[#fff] font-bold text-xl  py-[27px]'>{type}</p>
       <p className = 'font-[600] text-2xl  md:text-4xl py-5'>{price}</p>
       
       <div className = 'flex flex-col gap-[20px] '>
        {pointsArr?.length>0 && pointsArr.map((point,i)=>{
           return <div key = {i} className='flex text-[#6F6F6F] items-center gap-[20px] w-[85%] m-auto'>
             <img alt = 'tick' src = {point?.valid?greenTick:greenTick}/>
            <p>{point.content}</p>
           </div>
        })}  
       </div>
       <button onClick={onClick} className = 'inline-block w-[50%] rounded-[10px] p-[12px_0px] translate-y-[50%] text-[#000] font-bold no-underline pricing-link'>Get Now</button>
    </div>
   
    </>
  )
}

export default PricingPlan

import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from '../../../components/FAQ Section/faq';
import humanImg from "../../../assets/Allcoureses/human resources.png"
import Section3 from '../../../components/innerPage/section3/section3';

function Humanresources() {
     //section1Data
     const section1Data = {
      sectImg:humanImg,
      heading: (
        <h1 className="multiple-color">
          Learn <span>Human Resources</span> with the best instructors
        </h1>
      ),
      description:
       "The HR course covers fundamental HR principles and practices, such as talent acquisition, employee orientation, performance management, training, development, compensation, benefits, employee relations, and legal compliance. It provides students with the skills necessary to excel in a variety of HR positions, fostering a skilled workforce and aligning HR practices with organizational objectives.",
      link: "/internship-program",
    };
   
    //section2Data
    const section2Data = {
      description: "By enabling individuals to reach their full potential, human resource management can enhance an organization's overall performance. Learn in-depth information on the department's importance and operations.",
      points : [
         "Personnel Acquisition",
         "Payroll Generation",
         "Training and Development",
         "HR Planning",
      ]
    };
  
    //section 3
    const section3Data = [
      {
        topic: "Training Program Outline",
        subTopics: [
         "Basic Definition of Training|Introduction to Training:- Measure your skills here, and learn about boosting performance, productivity, skills, and knowledge.",
        ],
      },
      {
        topic: "Key Human Resource Functions",
        subTopics: [
        "In this section learn about Human resource planning, recruitment and selection, Performance management, Learning and development, Career planning, Function evaluation, Rewards, and Industrial relations."
        ],
      },
      {
        topic: " Recruitment and Selection",
        subTopics: [
         "Job Analysis and Job Descriptions: Learn how to analyse a job and interpret the specifications of the job."
        ],
      },
      {
        topic: "Compensation and Benefits",
        subTopics: [
         "Learn in details about: Flat Rate Only | Merit Pay | Profit and Gain Sharing | Merit Pay | Profit and Gain Sharing."
        ],
      },
      {
        topic: "Training and Development",
        subTopics: [
         "Learn what are the perspectives of training and development, the Training Cycle, their needs and the core competencies."
        ],
      }
    ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default Humanresources

import React from 'react';
import { Helmet } from 'react-helmet';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import cloudimg from "../../../assets/Allcoureses/Cloud computing.png";
import Section3 from './../../../components/innerPage/section3/section3';

function CloudComputing() {
  // section1Data
  const section1Data = {
    sectImg: cloudimg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Cloud Computing</span> with the best instructors
      </h1>
    ),
    description:
      "Embark on a transformative Cloud Computing course. Learn essential concepts, including virtualization, AWS and Azure services, deployment models, and security. Hands-on labs ensure practical skills, while expert instructors guide your journey. Elevate your career with in-demand cloud expertise. Join us to master cloud computing.",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description: "Create and design software applications based on the incoming demands and use.",
    points: [
      "Put your programming skills in real time applications and development.",
      "Learn to use and work with android studio, broadcast receivers and many more.",
      "Learn to apply specific programming languages into functioning apps.",
    ]
  };

  // section 3
  const section3Data = [
    {
      topic: "Introduction to cloud computing",
      subTopics: [
        "Architecture of cloud computing",
        "Overview of cloud computing"
      ],
    },
    {
      topic: "Cloud Computing Setup",
      subTopics: [
        "Cloud computing advantages and disadvantages",
        "Characteristics",
        "Cloud Computing applications"
      ],
    },
    {
      topic: "Cloud Computing Architecture",
      subTopics: [
        "Deployment models",
        "Public, Private, Hybrid, community",
      ],
    },
    {
      topic: "Cloud Computing Types",
      subTopics: [
        "Virtualization & types of virtualization",
        "Hypervisors & types of Hypervisors",
      ],
    },
    {
      topic: "Cloud Computing Needs",
      subTopics: [
        "Service level agreements",
        "Web service SLA",
        ".SLA Requirement",
        "Metrics for monitoring and Auditing",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Cloud Computing Internship Program | Cloud Computing Training</title>
        <meta name="description" content="The online cloud computing Internship course training program with certification at Academor is your ticket to understanding the game-changing realm." />
        <meta name="keywords" content="Online cloud computing internship program, Cloud Computing Courses, Cloud computing training programs, Cloud Deployment training classes" />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default CloudComputing;

import React, { useState } from 'react';
import './hiring.css';
import grid1 from '../../assets/contactUs/RepeatGrid.svg';
// import Thanks from './Thanks'; // Assuming 'Thanks.js' is in the same directory

function Hiring() {
  return (
    <>
      <div className="contactMain1">
        <div className="contact-details1">
          <p>
            <h1 className="contact-us1"><strong>Coming Soon!</strong></h1>
            We're working hard to bring you a platform to hire
            the best talent effortlessly. Stay tuned for updates!
          </p>
        </div>
      </div>
   </>
  );
}

export default Hiring;

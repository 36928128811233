import React, { useState, useEffect } from "react";
import "./internshipLanding.css";
import woman1 from "../../assets/internshipLanding/woman-1.png";
import woman2 from "../../assets/internshipLanding/woman-2.png";
import grid from "../../assets/internshipLanding/grid.svg";
import circle1 from "../../assets/internshipLanding/circle-1.svg";
import circle2 from "../../assets/internshipLanding/circle-2.svg";
import Lottie from "react-lottie";
import ballAnimation from "../../animations/internship-balls.json";
import downArrow from "../../animations/downArrow.json";
import SectionUnderlineHeading from "../../components/sectionUnderLineHeading/sectionUnderlineHeading";
import Pagination from "./../../components/pagination/pagination";
import InternshipDepartmentCard from "../../components/internshipDeptCard/internshipDeptCard";  
import { Helmet } from 'react-helmet';

function InternshipLanding() {
  const ballAnimationOptions = {
    loop: true,
    animationData: ballAnimation,
    rendererSettings: {

    },
  };
  const downnArrowAnimation = {
    loop: true,
    animationData: downArrow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const departmentArr = [
    { departmentName: "Computer Science",courseCount:8,description:'Customized Timespan , 1-1 Mentorship & Job Support , Live Sessions', path:'/computer-science' },
    { departmentName: "Electronics & Communication",courseCount:5,description:'Customized Timespan , 1-1 Mentorship & Job Support , Live Sessions', path:'/electronicsAndCommunication' },
    { departmentName: "Civil/Mechanical",courseCount:4,description:'Customized Timespan , 1-1 Mentorship & Job Support , Live Sessions', path:'/civilAndMechanical' },
    { departmentName: "Management",courseCount:5,description:'Customized Timespan , 1-1 Mentorship & Job Support , Live Sessions', path:'/Management' },
    { departmentName: "Biotechnology",courseCount:3,description:'Customized Timespan , 1-1 Mentorship & Job Support , Live Sessions', path:'/Biotechnology' },
  ];

  const [data,setData] = useState([])

  const [pageOptions, setPageOptions] = useState({
    currentPage: 1,
    pageSize: 0,
    totalCount: 1,
  });

  useEffect(()=>{
    let {currentPage,pageSize}=  pageOptions
    let start = (currentPage-1)*pageSize;
    let end = currentPage*pageSize
    let newData = departmentArr.slice(start,end)
      setData(newData)         
  },[pageOptions])

  useEffect(() => {
    if (window.innerWidth>=1024) {
      setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 6,totalCount:departmentArr.length }); 
      return
    }
    if (window.innerWidth>=768) {
      setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 4,totalCount:departmentArr.length }); 
      return
    }

    setPageOptions({ ...pageOptions, currentPage: 1, pageSize: 3,totalCount:departmentArr.length });
  }, [window.innerWidth]);
  return (
    <>
    <Helmet>
        <title>Internships for college students | Projects Based Internships</title>
        <meta name="description" content="Turn your ambition into a Academor online project based internships for college students with real time content from experts."/>
        <meta name="keywords" content="Project based internships for college students, online internship program for college students, engineering students internships, Internships in India for students"/>
      </Helmet>
      <div className="il-section-1 flex flex-wrap p-[10%] md:p-[5%_10%] items-center relative">
        <img
          alt="grid"
          src={grid}
          className="absolute top-0 left-0 w-[10%] md:w-[7%]"
        />
        <img
          alt="grid"
          src={grid}
          className="absolute bottom-0 right-0 w-[10%] md:w-[7%]"
        />

        <a
          href="#second-sec"
          className="absolute hidden md:block no-underline z-0 left-[10%] bottom-0 w-[8%]"
        >
          <Lottie options={downnArrowAnimation} />
        </a>
        <section className="w-[100%] py-[5%] md:p-0 md:w-[50%] relative ">
          <h1 className="multiple-color">
            Turn your ambition into a <span>success story</span>
          </h1>
          <p className="text-[#686868] text-[14px] pt-[10px] md:p-[10px_10px_0px_0px]  md:text-[16px]">
            Unlock your potential with Academor internship - where your potential meets opportunity.
          </p>
        </section>
        <div className="w-[100%] md:w-[50%] sec-1-img-collage">
          <div className=" w-[90%] md:w-[98%] mob:w-[70%] m-auto md:m-0 flex grid-cols-2 grid-rows-3">
            <div className="w-[50%] flex flex-col gap-[10px_0px]">
              <img alt="woman" src={woman1} />
              <img
                className="m-auto w-[50%] circle2"
                alt="circle"
                src={circle2}
              />
            </div>
            <div className="w-[50%] flex flex-col gap-[10px_0px]">
              <img
                className="m-auto w-[50%] circle1"
                alt="circle"
                src={circle1}
              />
              <img alt="woman" src={woman2} />
            </div>
          </div>
        </div>
      </div>
   
      <div id = 'second-sec' className="relative pt-[5%]">
      <div className="absolute top-0 left-0 w-[100%] h-[100%] -z-10">
          <Lottie options={ballAnimationOptions} />
        </div>
        <section className="relative z-10">
          <SectionUnderlineHeading heading="Departments" />
          <div className='flex flex-wrap p-[5%_5%_8%] gap-[50px_0px] md:gap-[60px_10%] xl:gap-[60px_9.5%]  lg:gap-[60px_3.333%] relative z-[10] overflow-hidden'>
          {data?.length > 0 &&
            data.map((item, i) => {
                return <InternshipDepartmentCard key = {i} {...item}/>
              })}
             
          </div>
         
          <Pagination
            totalCount={pageOptions.totalCount}
            pageSize={pageOptions.pageSize}
            currentPage={pageOptions.currentPage}
            onPageChange={(num,size) => {setPageOptions({...pageOptions,currentPage:parseInt(num),pageSize:parseInt(size)})}}
          />
        </section>
      </div>
    </>
  );
}

export default InternshipLanding;

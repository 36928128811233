import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import geneticImg from "../../../assets/Allcoureses/genetic engineering.png"
import Section3 from './../../../components/innerPage/section3/section3';

function GeneticEngineering() {
      //section1Data
  const section1Data = {
    sectImg:geneticImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Genetic Engineering</span> with the best instructors
      </h1>
    ),
    description:
     "Our curriculum will cover genetic engineering. Learn about biotechnology applications, gene manipulation technologies, and ethical issues. practical laboratory instruction and professional guidance. Perfect for biotechnology and research enthusiasts.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "You will learn more about genetic testing and research in this course. A significant and developing area of the applied sciences is genetic engineering. Know the fundamentals and foundations of the subject.",
    points : [
       "Gene Cloning",
       "Transgenic Technologies",
       "Genomics",
       "Forensics"
    ]
  };
     //section 3
     const section3Data = [
      {
        topic: " Introduction",
        subTopics: [
           "Introduction to genetics",
           "Mendelian Genetics",
           "Inheritance"
        ],
      },
      {
        topic: "Genetic Engineering Basics",
        subTopics: [
          "Mitosis, Meiosis and Ploidy",
          "X linked inheritance",
          "Applications of GE",
          "Mutations and Variations",
          "Plant Genetic Engineering",
        ],
      },
      {
        topic: " Strategies of Gene cloning",
        subTopics: [
         "Restriction Enzymes",
         "DNA libraries",
         "Vectors",
        ],
      },
      {
        topic: "Site-directed mutagenesis",
        subTopics: [
          "Gene Expression basics",
          "Northern Blotting",
          "Microarrays",
          "Gene expression",
          "Yeast 2 hybrid system"
        ],
      },
      {
        topic: "Transgenic Technologies",
        subTopics: [
          "Transgenic and gene knockout",
          "Targeted gene replacement",
          "Chromosomal engineering",
          "Gene editing Gene Silencing",
          "Transgenic plants"
        ],
      },
      {
        topic: "Genomics and Forensics",
        subTopics: [
          "Genetic defects and detection",
          "DNA profiling",
          "Applications of GE",
        ],
      },
    ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default GeneticEngineering

import React from 'react'
import './collaboration.css'
import Carousel from '../carousel/carousel'
import certificateone from '../../assets/collaboration/certificate (12).jpg'
import certificateTwo from "../../assets/collaboration/certificate (23).jpg"
import certificateThree from "../../assets/collaboration/certificate (34).jpg"

function Collaboration() {
    
  // course  owl carousal options
  const certificateCarousalOptions = {
    margin: 50,
    nav: false,
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
      },
    },
  };
  return (
    <>
    <div className='collaborationHero'>
        <div className='collaborationCarousel'>
            <Carousel customClass='certificates' options={certificateCarousalOptions}>
                <img src={certificateThree} alt="certificate"></img>
                <img src={certificateTwo} alt="certificate"></img>
                <img src={certificateone} alt="certificate"></img>
            </Carousel>
        </div>
        <div className='collaborationdesi'>
            {/* <h2>With</h2> */}
            <h1>Certifications</h1>
            <p>Your reliable gateway to an industrial career is Academor. Now in collabration with leading industry experts, we provide you with an internship that will give you a wealth of knowledge and practical experience for your future profession.</p>
            <a href="/internship-program" className='btn-primary'>Choose your course</a>
        </div>
    </div>
    </>
  )
}

export default Collaboration

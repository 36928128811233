import React from 'react';
import "./popUpModal.css"
  import logo from "../../assets/home/New_logo.png"

function PopUpModal({onClick}) {
  return (
    <>
  <div className='popUpMain'>
      <div className='popUpform'>
        <p className='popupcross' onClick={onClick}>&#10060;</p>
        <img src={logo} alt="logo"/>
        <div className='popupFromDetails'>
          <form>
          <label className='popUpFormEachDetail'>Name</label>
          <input type="text" placeholder='Name' name="name" required/>
          <label className='popUpFormEachDetail'>Email</label>
          <input type="text" placeholder='Email' name="Email" required/>
          <label className='popUpFormEachDetail'>Contact</label>
          <input type="text" placeholder='Contact' name="Contact" required/>
          <button type='submit' className='btn-primary formbtn'>Submit</button>
          </form>
        </div>
      </div>
    </div>
    </>
  )
}

export default PopUpModal

import React from 'react';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import stockImg from "../../../assets/Allcoureses/stock market.png"
import Section3 from './../../../components/innerPage/section3/section3';

function StockMarket() {

     
  //section1Data
  const section1Data = {
    sectImg : stockImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Stock Market</span> with the best instructors
      </h1>
    ),
    description:
     "Unlock the Power of Stocks! Discover management strategies in Stock Marketing with our engaging course. Your journey to financial expertise starts here.",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Profound concepts of stock markets, growing self-wealth and understand the working of the whole stock market.",
    points : [
      "Learn about capital markets and its analysis.",
      "Financial analysis and technical analysis.",
      "Fundamentals and theories that govern the charts of the stock market.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Capital markets",
      subTopics: [
        "Financial markets",
        "IPOs, their trade practices",
        "Capital flow",
      ],
    },
    {
      topic: "Fundamental analysis",
      subTopics: [
       "Analyze the economic structure",
       "Analyze the financial structure",
       "Maintain various cash transactions",
      ],
    },
    {
      topic: "Technical analysis",
      subTopics: [
        "Bespoke usage of computers",
        "Various financial estimation algorithms",
        "Determine scope and further course of action",
      ],
    }
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default StockMarket

import React from 'react';
import { Helmet } from 'react-helmet';
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import Section3 from './../../../components/innerPage/section3/section3';
import webimg from "../../../assets/Allcoureses/web development.png";

function WebDevelopment() {
  // section1Data
  const section1Data = {
    sectImg: webimg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Web development</span> with the best instructors
      </h1>
    ),
    description:
      "Take the first step towards Web development with diligent instructors. Follow the exciting path of courses which helps you master the concepts of Web development and related courses.",
    link: "/internship-program",
  };

  // section2Data
  const section2Data = {
    description: "Create, design and develop websites and many web platforms using concepts of web development.",
    points: [
      "Learn to use numerous development tools and MVC.",
      "Work with programming languages and enhance skills in web development.",
      "Understand concepts of web and web applications",
      "Information architecture and detailed designing.",
    ],
  };

  // section 3
  const section3Data = [
    {
      topic: "Introduction to internet",
      subTopics: ["Working of internet", "History of web"],
    },
    {
      topic: "HTML 5",
      subTopics: [
        "Development fundamentals.",
        "HTML tags",
        "Self-closing tags",
        "HTML elements",
      ],
    },
    {
      topic: "Advance HTML",
      subTopics: [
        "forms of HTML and HTML lists",
        "inline and block elements",
        "responsive HTML",
        "media",
      ],
    },
    {
      topic: "CSS",
      subTopics: [
        "CSS properties and selectors",
        "Box models, image, px vs em vs rem",
        "Flex box",
        "Responsive UI",
      ],
    },
  ];

  return (
    <>
      <Helmet>
        <title>Web Development Internship program | Web Development Courses</title>
        <meta
          name="description"
          content="Web development internship program courses training will master the art of creating dynamic websites by learning the secrets of HTML, CSS, JavaScript"
        />
        <meta
          name="keywords"
          content="Web Development Internship program, Web Development Courses, Best Web Development Internship courses, Full-stack Development Courses for Beginners, Online Web Development Certification Programs, Web Development Courses near me"
        />
      </Helmet>
      <Section1 {...section1Data} />
      <Section2 {...section2Data} />
      <Section3 data={[...section3Data]} />
      <PricingPlanSection />
      <MentorTestimonial />
      <FAQ />
    </>
  );
}

export default WebDevelopment;

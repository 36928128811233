import React from 'react'
import PricingPlanSection from "../../../components/innerPage/pricingPlanSection/pricingPlanSection";
import Section1 from "../../../components/innerPage/section1/section1";
import Section2 from "../../../components/innerPage/section2/section2";
import MentorTestimonial from "../../../components/mentorTestimonials/mentorTestimonial";
import FAQ from './../../../components/FAQ Section/faq';
import constructionImg from "../../../assets/Allcoureses/constructionPlaning.png"
import Section3 from './../../../components/innerPage/section3/section3';

function ConstructionPlanning() {
      //section1Data
  const section1Data = {
    sectImg:constructionImg,
    heading: (
      <h1 className="multiple-color">
        Learn <span>Construction Planning</span> with the best instructors
      </h1>
    ),
    description:
     "Explore Construction Management and Planning with us! Master essential skills for project execution success. Join immediately for a comprehensive educational experience. Create your destiny now!",
    link: "/internship-program",
  };
 
  //section2Data
  const section2Data = {
    description: "Learn in detail about the domain that relates to structural analysis and planning and physical properties that effects the overall build.",
    points : [
      "Structural and load design analysis.",
      "Fundamentals to Revit architecture and various methods to efficient planning.",
      "Geometry creation and use of various tools for profound building.",
      "Determine various parameters needed like man power, equipment and numerous other charts.",
    ]
  };

  //section 3
  const section3Data = [
    {
      topic: "Basic initiation",
      subTopics: [
        "AutoCAD and working on design",
        "Updated design platforms"
      ],
    },
    {
      topic: "Revit architecture",
      subTopics: [
        "Working with Autodesk revit",
        "Parametric modeling and drafting elements",
      ],
    },
    {
      topic: "Design and analysis of loads",
      subTopics: [
       "types of structural loads",
       "loading system in a mecahnism",
      ],
    },
    {
      topic: "Geometry creation",
      subTopics: [
        "Geometrical figures",
        "Terminologies involved in construction"
      ],
    },
  ];
  return (
    <>
    <Section1 {...section1Data} />
    <Section2 {...section2Data}/>
    <Section3 data={[...section3Data]}/>
    <PricingPlanSection/>
    <MentorTestimonial/>
    <FAQ/>
  </>
  )
}

export default ConstructionPlanning
